/* eslint-disable react/jsx-fragments */
/* eslint-disable react/no-unused-state */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import { Table, Badge, Alert } from 'reactstrap';
import { Link, BrowserRouter as Router } from 'react-router-dom';
import { withSelect } from '@wordpress/data';
import PropTypes from 'prop-types';
import { BallClipRotate } from 'react-pure-loaders';
import { getSubscription } from '../../../lib/apiFunctions';
import constants from '../../../lib/constants';
import Invoice from './Invoice';
import PreLoading from '../layout/PreLoading';
import { getFormattedDate } from '../../../lib/helper';
import { linkToPaymentPageUsingInvoiceId } from '../../../constants/routes';

export function orderStatus(status) {
  switch (status) {
    case constants.ORDER_STATUS_DRAFT:
      return <Badge color="warning">Draft</Badge>;
    case constants.ORDER_STATUS_PENDING:
      return <Badge color="warning">Pending</Badge>;
    case constants.ORDER_STATUS_ERROR:
      return <Badge color="danger">Error</Badge>;
    case constants.ORDER_STATUS_COMPLETE:
      return <Badge color="success">Completed</Badge>;
    case constants.ORDER_STATUS_EXPIRED:
      return <Badge color="danger">Expired</Badge>;
    case constants.ORDER_STATUS_EXPIRED_COMPLETE:
      return <Badge color="danger">Expired</Badge>;
    case constants.ORDER_STATUS_CANCELED:
      return <Badge color="warning">Canceled</Badge>;
    default:
      return <Badge color="danger">Not available</Badge>;
  }
}
function invoiceTable(id, orderId, createDate, amount, currency, status) {
  return (
    <tr key={id}>
      <th scope="row">{id}</th>
      <td>{orderId}</td>
      <td>{createDate}</td>
      <td>
        {amount} {currency}
      </td>
      <td>{orderStatus(status)}</td>
      <td className="d-flex">
        <Invoice buttonLabel="View Invoice" invoiceId={id} key={id} />
        {status == '2' && (
          <a
            href={linkToPaymentPageUsingInvoiceId(id)}
            target="_blank"
            className="btn btn-sm btn-primary"
          >
            Make payment
          </a>
        )}
      </td>
    </tr>
  );
}
class SubscriptionData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      invoices: [],
      status: false
    };
    this.callbackFunction = this.callbackFunction.bind(this);
  }

  // returns the data from api
  callbackFunction(message, dataRecieved) {
    if (message === true) {
      this.setState({
        loading: false,
        status: true,
        invoices: dataRecieved
      });
    }
    if (message === false) {
      this.setState({
        status: false,
        loading: false,
        invoices: dataRecieved
      });
    }
  }

  componentDidMount() {
    // 11908 id with invoice example for testing
    // this.props.match.params.id
    const { match, projectId } = this.props;
    getSubscription(projectId, this.callbackFunction);
  }

  render() {
    const { match, projectId } = this.props;
    const { invoices, loading, status } = this.state;
    if (loading === true) return <PreLoading single />;
    if (!status) return <Alert color="danger">Something went wrong</Alert>;
    let invoiceCards;
    if (status) {
      invoiceCards = invoices.invoices.map(invoice => {
        return invoiceTable(
          invoice.id,
          invoice.orderId,
          getFormattedDate(invoice.createDate),
          invoice.amount,
          invoice.currency,
          invoice.status
        );
      });
    }
    return (
      <React.Fragment>
        {/* <Router>
          <Link
            to={`/apps/${match.params.id}`}
            className="text-dark text-decoration-none"
          >
            <h6>
              <i className="fas fa-arrow-left" />
              Back
            </h6>
          </Link>
        </Router> */}
        <div className="subscription-data overflow-auto">
          <div className="px-3 pt-3 bg-white dashboard-box rounded mb-2">
            <h5 className="border-bottom pb-3">
              Subscription # {invoices.subscription.subscription_id}
            </h5>
            <div className="">
              <div className="d-flex my-3">
                <img
                  src="https://storage.googleapis.com/stateless-appmaker-pages-wp/2019/06/0c3f0ec5-group-309.png"
                  alt="invoices"
                  width="50px"
                  height="50px"
                  className="mr-3"
                  draggable="false"
                />
                <span>
                  <h5 className="font-weight-bold">Invoices</h5>
                  <span className="small-label-text">
                    Invoice(s) of project # {projectId}
                  </span>
                </span>
              </div>
              <Table>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Order Id</th>
                    <th>Create Date</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th />
                  </tr>
                </thead>
                <tbody>{invoiceCards}</tbody>
              </Table>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
SubscriptionData.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export default withSelect(select => {
  const project = select('appmaker/core').getCurrentProject();
  return { projectId: project.id };
})(SubscriptionData);
