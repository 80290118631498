import React, { useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { withSelect } from '@wordpress/data';
import * as ROUTES from '../../../constants/routes';
import logo from '../../../images/logo.svg';
import { DashIcon, BackIcon } from './Icons';
import DocComponent from './components/docs';
import SideBar from './sidebar';

const NavbarVertical = props => {
  const [sideBar, setSideBar] = useState(false);
  const toggleSideBar = () => setSideBar(!sideBar);
  const { isExpired, appId, isAdmin, loginProvider } = props;
  const history = useHistory();

  return (
    <React.Fragment>
      <button
        className={`mobile-menu-toggle ${sideBar === true ? 'active' : ''}`}
        onClick={toggleSideBar}
      >
        {sideBar === false ? (
          <i className="fas fa-bars" />
        ) : (
          <i className="fas fa-times" />
        )}
      </button>
      <nav
        className={`navbar navbar-vertical fixed-left navbar-expand-md navbar-light ${
          sideBar === true ? 'active' : ''
        }`}
      >
        {/* top with app icon */}
        <a className="navbar-brand d-flex">
          <img
            src={
              props.appLogo ||
              'https://storage.googleapis.com/stateless-appmaker-pages-wp/2020/02/98fa1037-default-applogo.png'
            }
            width="50"
            height="50"
            className="d-inline-block align-top rounded-lg mr-3"
            alt="app-logo"
          />
          <span className="d-flex flex-column my-auto">
            <h4 className="app-name text-truncate mb-0" title={props.appName}>
              {props.appName}
            </h4>
            <small className="small-label-text text-primary text-uppercase">
              {props.project.template}
            </small>
          </span>
        </a>

        {/* navbar options */}
        <ul className="navbar-nav main-nav mt-2">
          <li className="nav-item">
            <NavLink
              onClick={toggleSideBar}
              to={`/apps/${props.appId}`}
              activeClassName="active"
              className="vertical-nav-hover nav-link text-dark rounded-lg text-decoration-none m-auto"
            >
              <DashIcon fill="#202020" />
              Dashboard
            </NavLink>
          </li>
          <SideBar
            template={props.project.template}
            appId={appId}
            otherProps={{
              history,
              nameChanged: props.nameChanged,
              newPage: props.newPage,
              afterNewPage: data => {
                if (props.afterNewPage !== undefined) {
                  props.afterNewPage(data);
                }
              }
            }}
            subscriptionExpired={isExpired}
            toggleSideBar={toggleSideBar}
            isAdmin={isAdmin}
          />
          <DocComponent />
        </ul>

        {/* bottom options with appmaker logo */}
        {loginProvider !== 'shopify' && (
          <ul className="navbar-nav fixed-bottom pb-3 pl-4 position-absolute">
            <li className="nav-item pb-2">
              <a
                onClick={toggleSideBar}
                href={ROUTES.MANAGE}
                strict
                className="back-nav-hover nav-link text-dark rounded-lg text-decoration-none"
              >
                <BackIcon fill="#202020" />
                My Apps
              </a>
            </li>
            <li className="nav-item">
              <a href="https://appmaker.xyz">
                <img src={logo} alt="Appmaker.xyz" width="120px" />
              </a>
            </li>
          </ul>
        )}
      </nav>
    </React.Fragment>
  );
};

export default withSelect((select, props, dispatch) => {
  const project = select('appmaker/core').getCurrentProject();
  const user = select('appmaker/core').getUser();
  const { loginProvider } = user || {};
  const isAdmin = user && user.email.includes('@appmaker.xyz');
  return { project, isAdmin, loginProvider };
})(NavbarVertical);
