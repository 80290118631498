import React, { useState } from 'react';
import ActionSelect from './ActionSelect';
import MultiSelect from './MultiSelect';
/**
 * Converts Actions types into formSchema for JSON formSchema
 * @param {ActionTypes} data
 */
const convertSchema = data => {
  const oneOf = [];
  data.map(item => {
    if (item.subAction) {
      const convertedAction = {
        properties: {
          action: {
            enum: [item.id]
          },
          [`${item.id}_${item.subAction.id}`]: {
            type: 'string',
            title: item.subAction.title
          }
        }
      };
      if (item.subAction.subAction && item.subAction.subAction.string) {
        convertedAction.properties[
          `${item.id}_${item.subAction.subAction.id}`
        ] = {
          type: 'string',
          title: item.subAction.subAction.title,
          default: item.subAction.subAction.defaultValue
        };
      }
      oneOf.push(convertedAction);
    }
  });

  const schema = {
    properties: {
      action: {
        type: 'string',
        title: 'Choose an action'
        // enumNames,
        // enum: enumValues
      }
    },
    required: ['action'],
    dependencies: {
      action: {
        oneOf
      }
    }
  };

  return schema;
};
/**
 *  Create UI schema for actionTypes
 * @param {ActionTypes} data
 */
const convertUISchema = (data, projectId) => {
  const actionItems = [];
  const UISchema = {};
  data.map(item => {
    actionItems.push({ value: item.id, label: item.title });
    if (item.subAction && item.subAction.type === 'select') {
      UISchema[`${item.id}_${item.subAction.id}`] = {
        'ui:widget': props => (
          <ActionSelect
            projectId={projectId}
            dataSource={item.subAction.dataSource}
            onChange={data => {
              props.onChange(data);
            }}
            value={props.value}
          />
        )
      };
      if (
        item.subAction.subAction &&
        item.subAction.subAction.type === 'select'
      ) {
        UISchema[`${item.id}_${item.subAction.id}`] = {
          'ui:field': 'multiple',
          data: item.subAction,
          parentId: item.id
        };
      }
      if (
        item.subAction.subAction &&
        item.subAction.subAction.type === 'select-simple'
      ) {
        UISchema[`${item.id}_${item.subAction.id}`] = {
          'ui:field': 'simple-multiple',
          data: item.subAction,
          parentId: item.id
        };
      }
    }
    if (item.subAction && item.subAction.type === 'select-options') {
      UISchema[`${item.id}_${item.subAction.id}`] = {
        'ui:widget': props => (
          <ActionSelect
            projectId={projectId}
            options={item.subAction.dataSource.options}
            onChange={data => {
              props.onChange(data);
            }}
            value={props.value ? props.value.value : undefined}
          />
        )
      };
    }
    if (item.subAction && item.subAction.type === 'select-multi') {
      UISchema[`${item.id}_${item.subAction.id}`] = {
        'ui:widget': props => (
          <MultiSelect
            isMulti
            isCreatable
            projectId={projectId}
            onChange={data => {
              props.onChange(data);
            }}
            dataSource={item.subAction.dataSource}
            value={props.value}
          />
        )
      };
    }
  });
  UISchema.action = {
    'ui:widget': props => {
      return (
        <ActionSelect
          onChange={data => {
            props.onChange(data.value);
          }}
          options={actionItems}
          value={props.value}
        />
      );
    }
  };
  return UISchema;
};
/**
 * Add the params inside the formdata;
 * @param {Object} formData
 */
const convertFormDataWithParams = formData => {
  const convertedData = formData;
  switch (formData.action) {
    case 'OPEN_INAPP_PAGE': {
      convertedData.params =
        formData.OPEN_INAPP_PAGE_id !== undefined
          ? {
              value: formData.OPEN_INAPP_PAGE_id.id
            }
          : {};
      break;
    }
    case 'OPEN_CATEGORY': {
      convertedData.params =
        formData.OPEN_CATEGORY_PRODUCT_ID !== undefined
          ? {
              value: formData.OPEN_CATEGORY_PRODUCT_ID.id
            }
          : {};
      break;
    }
    case 'OPEN_PRODUCT_TAG': {
      convertedData.params =
        formData.OPEN_PRODUCT_TAG_PRODUCT_ID !== undefined
          ? {
              value: formData.OPEN_PRODUCT_TAG_PRODUCT_ID.id
            }
          : {};
      break;
    }
    case 'OPEN_POST_PAGE': {
      convertedData.params =
        formData.OPEN_POST_PAGE_PRODUCT_ID !== undefined
          ? {
              value: formData.OPEN_POST_PAGE_PRODUCT_ID.id
            }
          : {};
      break;
    }
    case 'OPEN_URL': {
      convertedData.params =
        formData.OPEN_URL_url !== undefined
          ? {
              value: formData.OPEN_URL_url
            }
          : {};
      break;
    }
    case 'OPEN_IN_WEBVIEW': {
      convertedData.params =
        formData.OPEN_IN_WEBVIEW_url !== undefined
          ? {
              value: formData.OPEN_IN_WEBVIEW_url
            }
          : {};
      break;
    }
    case 'OPEN_PRODUCT': {
      convertedData.params =
        formData.OPEN_PRODUCT_PRODUCT_ID !== undefined
          ? {
              value: formData.OPEN_PRODUCT_PRODUCT_ID.id
            }
          : {};
      break;
    }
    case 'OPEN_PRODUCT_BY_CATEGORY': {
      convertedData.params =
        formData.OPEN_PRODUCT_BY_CATEGORY_PRODUCT_ID !== undefined
          ? {
              value: formData.OPEN_PRODUCT_BY_CATEGORY_PRODUCT_ID.id
            }
          : {};
      break;
    }
    case 'LIST_POST': {
      convertedData.params =
        formData.OPEN_PRODUCT_BY_CATEGORY_PRODUCT_ID !== undefined
          ? {
              value: formData.OPEN_PRODUCT_BY_CATEGORY_PRODUCT_ID.id
            }
          : {};
      break;
    }
    case 'OPEN_POST': {
      convertedData.params =
        formData.OPEN_POST_PAGE_id !== undefined
          ? {
              value: formData.OPEN_POST_PAGE_id.id
            }
          : {};
      break;
    }
    case 'LIST_PRODUCT': {
      convertedData.params =
        formData.OPEN_CATEGORY_PRODUCT_ID !== undefined
          ? {
              value: formData.OPEN_CATEGORY_PRODUCT_ID.id
            }
          : {};
      break;
    }
    default:
      convertedData.params = {};
      break;
  }
  return convertedData;
};

export { convertSchema, convertUISchema, convertFormDataWithParams };
