import React, { useEffect } from 'react';
import { useToasts } from 'react-toast-notifications';
import { withSelect } from '@wordpress/data';
import FormBuilder from './FormBuilder';

const UpdateApp = props => {
  const { match = {}, isAdmin = false, project = {} } = props;
  const { params = {} } = match;
  const { id } = params;
  const { addToast } = useToasts();
  useEffect(() => {
    if (project.template === 'shopify' && parseInt(project.id) > 151416) {
      window.location.href = `https://v3.appmaker.xyz/${project.id}/dashboard/app-design/app-icon`;
      return false;
    }
  }, []);
  return <FormBuilder projectId={id} addToast={addToast} isAdmin={isAdmin} />;
};

export default withSelect(select => {
  const user = select('appmaker/core').getUser();
  const project = select('appmaker/core').getCurrentProject();
  const isAdmin = user && user.email.includes('@appmaker.xyz');
  return { isAdmin, project };
})(UpdateApp);
