/* eslint-disable import/prefer-default-export */
import React from 'react';
import ToggleSwitch from './Widgets/toggleSwitch';
import ColorPicker from './Widgets/colorPicker';
import MultiSelect from './Widgets/multiSelect';
import FileUpload from './Widgets/fileUpload';
import SimpleSelect from './Widgets/simpleSelect';
import Language from './Widgets/language';

export const getAllWidgets = ({
  projectId,
  addToast = () => {},
  translationTab,
  updateDefaultValues = {}
}) => {
  return {
    customToggle: ToggleSwitch,
    customColorPicker: ColorPicker,
    customMultiSelect: MultiSelect,
    customFileUpload: props => {
      return (
        <FileUpload projectId={projectId} addToast={addToast} {...props} />
      );
    },
    customSimpleSelect: SimpleSelect,
    customLanguageTab: props => {
      return (
        <Language
          {...props}
          projectId={projectId}
          addToast={addToast}
          translationTab={translationTab}
          availableLanguageList={updateDefaultValues.languageList}
        />
      );
    }
  };
};
