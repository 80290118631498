import React, { Component } from 'react';
import { Button, Form, FormGroup, Label, Input, Spinner } from 'reactstrap';
import { withToastManager } from 'react-toast-notifications';
import {
  getAccoutSetting,
  updateAccountSettings
} from '../../../lib/apiFunctions';
import PreLoading from '../../dashboard/layout/PreLoading';

class ChangePass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      updating: false,
      profileData: {}
    };
  }

  getDataOfProfile() {
    getAccoutSetting()
      .then(response => response.data)
      .then(data => {
        const currentState = this.state;
        currentState.profileData = data;
        currentState.loading = false;
        this.setState(currentState);
      });
  }

  updatePassword() {
    const { toastManager } = this.props;
    const currentPassword = document.getElementById('curr_pass').value;
    const newPassword = document.getElementById('new_pass').value;
    const repeatPassword = document.getElementById('rep_new_pass').value;
    // console.log(this.state, newPassword, repeatPassword, currentPassword);
    if (newPassword === repeatPassword) {
      const currentState = this.state;
      currentState.loading = false;
      currentState.updating = true;
      this.setState(currentState);
      const formData = new FormData();
      formData.append('User[currentPassword]', currentPassword);
      formData.append('Profile[full_name]', currentState.profileData.fullname);
      formData.append('User[username]', currentState.profileData.username);
      formData.append('User[email]', currentState.profileData.email);
      formData.append('User[newPassword]', newPassword);
      updateAccountSettings(formData)
        .then(response => response.json())
        .then(resp => {
          if (resp.status === 'success') {
            const currentState = this.state;
            currentState.loading = false;
            currentState.updating = false;
            this.setState(currentState);
            toastManager.add('Updated Successfully', {
              appearance: 'success',
              autoDismiss: true
            });
          } else {
            const currentState = this.state;
            currentState.loading = false;
            currentState.updating = false;
            this.setState(currentState);
            toastManager.add('Failed..incorrect password', {
              appearance: 'error',
              autoDismiss: true
            });
          }
        })
        .catch(error => {
          const currentState = this.state;
          currentState.loading = false;
          currentState.updating = false;
          this.setState(currentState);
          toastManager.add('Something went wrong', {
            appearance: 'error',
            autoDismiss: true
          });
        });
    } else {
      toastManager.add('Password mismatch', {
        appearance: 'error',
        autoDismiss: true
      });
    }
  }

  componentDidMount() {
    this.getDataOfProfile();
  }

  render() {
    if (this.state.loading) return <PreLoading single />;
    return (
      <div className="dashboard-box bg-white rounded mb-2 account-settings-box p-4">
        <h5 className="font-weight-bold mb-3">Change password</h5>
        <Form>
          <FormGroup>
            <Label htmlFor="curr_pass">Current password</Label>
            <Input type="password" id="curr_pass" />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="new_pass">New password</Label>
            <Input type="password" id="new_pass" />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="rep_new_pass">Repeat new password</Label>
            <Input type="password" id="rep_new_pass" />
          </FormGroup>
          <div className="text-right">
            <Button
              color="primary"
              className="px-5"
              onClick={() => {
                if (this.state.loading === false) this.updatePassword();
              }}
            >
              {this.state.updating ? (
                <Spinner grow="sm" color="danger" />
              ) : (
                'update'
              )}
            </Button>
          </div>
        </Form>
      </div>
    );
  }
}

export default withToastManager(ChangePass);
