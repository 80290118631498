import React from 'react';
import {
  MediaPlaceholder,
  RichText,
  InspectorControls
} from '@wordpress/block-editor';
import { ToggleControl, PanelBody, Button } from '@wordpress/components';
import { __ } from '@wordpress/i18n';
import DeleteActionButton from '../../components/Toolbar';

import BannerImage from '../../components/BannerImage';
import { extractTextFromHTML } from '../../helper';

export default props => {
  const { attributes, setAttributes, className, isSelected } = props;
  const { image, showText } = attributes;
  return (
    <div>
      <DeleteActionButton
        action={() => {
          setAttributes({ image: undefined });
        }}
      />
      <InspectorControls>
        {/* <PanelBody title="Banner settings" initialOpen>
          <ToggleControl
            label={__('Show image title', 'newspack-blocks')}
            checked={showText}
            onChange={() => setAttributes({ showText: !showText })}
          />
        </PanelBody> */}
      </InspectorControls>
      <div className={className}>
        {/** NOTE  IMAGE DELETE */}
        {/* {image !== undefined && isSelected && (
          <Button
            isLarge
            onClick={() => {
              setAttributes({ image: undefined });
            }}
          >
            <i className="fas fa-times" />
          </Button>
        )} */}
        {image === undefined && (
          <MediaPlaceholder
            onSelectURL={url => {
              setAttributes({
                image: {
                  url
                }
              });
            }}
            onSelect={data => {
              // condition in meta because each api having different response of the meta
              setAttributes({
                image: {
                  url: data.url,
                  fileName: data.filename,
                  id: data.id,
                  title: data.title
                },
                imageContainerStyle: {
                  height: data.meta !== undefined ? data.meta.height : '100',
                  width: data.meta !== undefined ? data.meta.width : '100'
                },
                thumbnail_meta: {
                  height:
                    data.meta !== undefined ? data.meta.height : undefined,
                  width: data.meta !== undefined ? data.meta.width : undefined
                }
              });
            }}
            allowedTypes={['image']}
            multiple={false}
            labels={{ title: 'Banner' }}
            value={{ id: image && image.id, src: image && image.url }}
          />
        )}
        {image && (
          <BannerImage imageSrc={image.url}>
            {showText && (
              <RichText
                allowedFormats={[]}
                value={attributes.text}
                withoutInteractiveFormatting
                onChange={text => {
                  setAttributes({ text: extractTextFromHTML(text) });
                }}
                placeholder="Image title"
              />
            )}
          </BannerImage>
        )}
      </div>
    </div>
  );
};
