// @flow strict

import * as React from 'react';
import { withToastManager } from 'react-toast-notifications';
import { FormGroup, Label } from 'reactstrap';
import '@wordpress/editor'; // This shouldn't be necessary
import { withSelect, dispatch } from '@wordpress/data';
import SortableTree, {
  addNodeUnderParent,
  changeNodeAtPath,
  defaultGetNodeKey,
  removeNodeAtPath
} from 'react-sortable-tree';
import FileExplorerTheme from 'react-sortable-tree-theme-full-node-drag';
import Helmet from 'react-helmet';

import { Popover, Button } from '@wordpress/components';
import '@wordpress/format-library';

import { MediaUpload } from '@wordpress/block-editor';
import Select from 'react-select';

import ActionForms from '../../hooks/action/components/ActionForm';
import './style.css';
import PreLoading from '../dashboard/layout/PreLoading';
import { verifyAction } from './verifyAction';
import Languages from '../InAppPageEditor/Languages';
import { trackEvent } from '../../utils/tracker';

class InAppPageMenuEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      loader: ! (props.menu !== undefined && props.menu.length !== undefined),
      treeData: props.menu ? props.menu : [],
      addNewMenuParent: false,
      menuType: 'title',
      newNode: {},
      parentId: null,
      isEditing: false,
      nodeData: '',
      currentLanguage: undefined,
      defaultSelectedMenu: 'default'
    };
    this.switchLanguages = this.switchLanguages.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { treeData } = this.state;
    if (nextProps.menu !== undefined && nextProps.menu.length !== undefined) {
      this.setState({ treeData: nextProps.menu, loader: false });
    }
  }

  // For languages
  async switchLanguages(data) {
    if (data) {
      this.setState({ isLoading: true, loader: true });
      let menuKey;
      if (data.value === 'default') {
        menuKey = 'mainmenu';
      } else {
        menuKey = `mainmenu-${data.value}`;
      }
      await dispatch('appmaker/menu').setMenuKey(menuKey);
      this.setState({
        isLoading: false,
        loader: true,
        defaultSelectedMenu: data.value,
        currentLanguage: menuKey
      });
    }
  }

  // To add item to the tree
  addItem(newNode, parentKey) {
    const { treeData } = this.state;
    const { toastManager } = this.props;
    if (newNode.type === undefined) {
      toastManager.add('Type is empty', {
        appearance: 'error',
        autoDismiss: true
      });
      return;
    }
    if (newNode.title === '' || newNode.title === undefined) {
      toastManager.add('Title is empty', {
        appearance: 'error',
        autoDismiss: true
      });
      return;
    }
    const convertedNodeData = newNode;
    if (newNode.action) {
      const verify = verifyAction(newNode.action);
      if (verify !== true) {
        toastManager.add('Complete the Action', {
          appearance: 'error',
          autoDismiss: true
        });
        return;
      }
    }

    const addItems = addNodeUnderParent({
      treeData,
      newNode: convertedNodeData,
      getNodeKey: defaultGetNodeKey,
      parentKey: parentKey === undefined ? null : parentKey, // parent key should be null for adding item as root's child,
      expandParent: true
    });

    try {
      trackEvent('menu_item_added', {
        project_id: this.props.match.params.id,
        type: newNode.type ? newNode.type.value : '',
        action: convertedNodeData.action ? convertedNodeData.action.value : ''
      });
    } catch (error) {}

    this.setState({
      treeData: addItems.treeData,
      addNewMenuParent: false,
      newNode: {}
    });
  }

  // To delete item
  deleteItem(obj) {
    const { treeData } = this.state;
    const deleteItem = removeNodeAtPath({
      treeData,
      path: obj.path,
      getNodeKey: defaultGetNodeKey,
      ignoreCollapsed: true
    });

    try {
      trackEvent('menu_item_deleted', {
        project_id: this.props.match.params.id
      });
    } catch (error) {}

    this.setState({
      treeData: deleteItem,
      addNewMenuParent: false
    });
  }

  // To Edit
  editItem(newNode) {
    const { toastManager } = this.props;
    const { nodeData, treeData } = this.state;
    const convertedNodeData = newNode;
    if (newNode.action) {
      const verify = verifyAction(newNode.convertedAction);
      if (verify !== true) {
        toastManager.add('Complete the Action', {
          appearance: 'error',
          autoDismiss: true
        });
        return;
      }
    }
    const data = changeNodeAtPath({
      treeData,
      path: nodeData.path,
      newNode: convertedNodeData,
      getNodeKey: defaultGetNodeKey
    });
    this.setState({ treeData: data });
    try {
      trackEvent('menu_item_edited', {
        project_id: this.props.match.params.id,
        type: newNode.type ? newNode.type.value : '',
        action: convertedNodeData.action ? convertedNodeData.action.value : ''
      });
    } catch (error) {}
    this.saveMenuItem(data);

    // toastManager.add('Updated, Press Save to sync to app', {
    //   appearance: 'success',
    //   autoDismiss: true
    // });
  }

  // Delete unwanted data
  converterDeleteAction(data) {
    const out = data.map(item => {
      const convertedData = item;
      if (item.children && item.children.length !== 0) {
        this.converterDeleteAction(convertedData.children);
      }
      if (item.convertedAction) {
        delete convertedData.convertedAction;
        return convertedData;
      }
      return convertedData;
    });
    return out;
  }

  // Converter to add icon if not exist
  addIconIfNotExist(data) {
    const { isLoading } = this.state;
    if (data !== undefined) {
      return data.map(item => {
        if (
          item.type &&
          item.type.value === 'menu_item' &&
          item.icon === undefined
        ) {
          item.icon = '';
        }
        if (item.childeren && item.children.length !== 0) {
          item.children = this.addIconIfNotExist(item.children);
        }
        // console.log(item);
        return item;
      });
    }
  }

  // save menu Item
  async saveMenuItem(data) {
    const { toastManager, user = {} } = this.props;
    const { currentLanguage } = this.state;
    this.setState({ isLoading: true });
    try {
      const convertedData = data;
      const iconErrorCheck = this.addIconIfNotExist(convertedData);
      const response = dispatch('appmaker/menu').saveMenu(
        iconErrorCheck,
        currentLanguage
      );
      const respJson = await response;

      try {
        trackEvent(
          'menu_saved',
          {
            project_id: this.props.match.params.id,
            menu_count: data.length,
            email: user.email
          },
          {
            mautic: true
          }
        );
      } catch (error) {}

      toastManager.add('Saved Successfully', {
        appearance: 'success',
        autoDismiss: true
      });

      this.setState({
        treeData: data,
        isLoading: false,
        addNewMenuParent: false
      });
    } catch (error) {
      try {
        trackEvent('menu_save_error', {
          project_id: this.props.match.params.id
        });
      } catch (error) {}
      toastManager.add('Unable to save', {
        appearance: 'error',
        autoDismiss: true
      });
    }

    this.setState({ isLoading: false });
  }

  render() {
    const { toastManager, languages } = this.props;
    const checkLanguageLength = languages => {
      if (languages !== undefined && languages.length > 0) return true;
      return false;
    };
    const {
      isLoading,
      loader,
      treeData,
      addNewMenuParent,
      menuType,
      newNode,
      parentId,
      isEditing,
      defaultSelectedMenu
    } = this.state;
    const getNodeKey = ({ treeIndex }) => treeIndex;
    return (
      <div>
        <Helmet>
          <title>In-App Menu - Appmaker dashboard</title>
        </Helmet>
        {/* <CustomizerTopbar /> */}
        <div className="row bg-light wrap">
          <div className="col-md-9 bg-light app-build-area wrap overflow-auto position-relative">
            <div className="row">
              <div
                className="col-md-3"
                onClick={() => {
                  this.setState({ addNewMenuParent: false });
                }}
              />
              <div className="col-md-6">
                {loader && (
                  <React.Fragment>
                    <PreLoading app />
                    <PreLoading app />
                    <PreLoading app />
                  </React.Fragment>
                )}

                {!loader && (
                  <div className="menu-view mt-3">
                    <SortableTree
                      theme={FileExplorerTheme}
                      treeData={treeData}
                      canDrop={data => {
                        // false to disable drop
                        return !(
                          data.nextParent !== null &&
                          data.nextParent.type &&
                          data.nextParent.type.value === 'title'
                        );
                      }}
                      onChange={treeData => this.setState({ treeData })}
                      generateNodeProps={obj => {
                        const buttons = [];
                        // NOTE Delete button
                        buttons.push(
                          <button
                            className="btn btn-sm btn-outline-danger"
                            type="button"
                            onClick={() => this.deleteItem(obj)}
                            style={{ marginRight: '8px' }}
                          >
                            <i className="far fa-trash-alt" />
                          </button>
                        );

                        // NOTE Add child node
                        if (obj.node.type.value !== 'title')
                          buttons.push(
                            <button
                              className="btn btn-sm btn-secondary"
                              type="button"
                              onClick={() => {
                                this.setState(
                                  {
                                    newNode: JSON.parse(JSON.stringify({})),
                                    addNewMenuParent: false,
                                    parentId: obj.path.slice(-1)[0],
                                    isEditing: false
                                  },
                                  () => {
                                    this.setState({ addNewMenuParent: true });
                                  }
                                );
                              }}
                            >
                              <i className="fas fa-plus" />
                            </button>
                          );
                        // NOTE add edit button
                        buttons.push(
                          <button
                            className="btn btn-sm btn-secondary"
                            type="button"
                            style={{ marginLeft: 6 }}
                            onClick={() => {
                              this.setState({
                                isEditing: true,
                                newNode: JSON.parse(JSON.stringify(obj.node)),
                                addNewMenuParent: true,
                                nodeData: JSON.parse(JSON.stringify(obj))
                              });
                            }}
                          >
                            <i className="fas fa-edit" />
                          </button>
                        );
                        return {
                          title: () => {
                            return (
                              <span className="tree-menu-item d-block">
                                {obj.node.icon &&
                                  obj.node.icon.display_value &&
                                  obj.node.type !== undefined &&
                                  obj.node.type.value !== 'title' && (
                                    <img
                                      src={
                                        obj.node.icon !== undefined
                                          ? obj.node.icon.display_value
                                          : ''
                                      }
                                      alt="menu-image"
                                      style={{ maxHeight: 25, maxWidth: 25 }}
                                    />
                                  )}
                                {obj.node.type.value === 'title' ? (
                                  <span className="menu-title my-auto">
                                    {typeof obj.node.title === 'string' &&
                                      obj.node.title}
                                    {typeof obj.node.title !== 'string' &&
                                      typeof obj.node.title === 'object' &&
                                      obj.node.title !== undefined &&
                                      obj.node.title.value !== undefined &&
                                      `${obj.node.title.value}`}
                                  </span>
                                ) : (
                                  <span className="my-auto">
                                    {typeof obj.node.title === 'string' &&
                                      obj.node.title}
                                    {typeof obj.node.title !== 'string' &&
                                      typeof obj.node.title === 'object' &&
                                      obj.node.title !== undefined &&
                                      obj.node.title.value !== undefined &&
                                      `${obj.node.title.value}`}
                                  </span>
                                )}
                              </span>
                            );
                          },
                          buttons,
                          onClick: () => {}
                        };
                      }}
                    />
                    <Button
                      className="btn btn-secondary btn-block mt-2"
                      disabled={isLoading}
                      onClick={() => {
                        this.setState(
                          {
                            addNewMenuParent: false,
                            parentId: null,
                            newNode: {},
                            isEditing: false
                          },
                          () => {
                            this.setState({ addNewMenuParent: true });
                          }
                        );
                      }}
                    >
                      Add Menu Item
                    </Button>
                  </div>
                )}
              </div>
              <div
                className="col-md-3"
                onClick={() => {
                  this.setState({ addNewMenuParent: false });
                }}
              />
            </div>
          </div>
          <div className="col-md-3 bg-white wrap overflow-auto">
            <h6 className="font-weight-bold my-3 pb-2">Settings</h6>
            {languages && checkLanguageLength(languages) && (
              <FormGroup>
                <Label for="examplePassword">Languages:</Label>
                <Languages
                  isDisabled={isLoading || loader}
                  defaultValue={defaultSelectedMenu}
                  languages={languages}
                  onChange={data => {
                    this.switchLanguages(data);
                  }}
                />
              </FormGroup>
            )}
            <Button
              disabled={isLoading || loader}
              className="btn btn-success btn-block"
              onClick={() => {
                this.saveMenuItem(this.state.treeData);
              }}
            >
              <i className="fas fa-save mr-2" />
{' '}
              {isLoading === true ? 'Saving...' : 'Save'}
            </Button>
            {addNewMenuParent && (
              <div className="my-2">
                <p className="text-primary">Add Menu Item</p>
                <div className="form-group">
                  <label htmlFor="menuItemTitle">Menu title</label>

                  <input
                    id="menuItemTitle"
                    className="form-control"
                    type="text"
                    value={newNode.title}
                    placeholder="Title"
                    onChange={data => {
                      const newNodeData = newNode;
                      newNodeData.title = data.target.value;
                      this.setState({ newNode: newNodeData });
                    }}
                  />
                </div>
                <label>Select menu type</label>

                <Select
                  value={
                    newNode.type
                      ? {
                          value: newNode.type.value,
                          label: newNode.type.display_value
                        }
                      : undefined
                  }
                  options={[
                    {
                      value: 'title',
                      display_value: 'Title',
                      label: 'Title',
                      display: true
                    },
                    {
                      value: 'menu_item',
                      display_value: 'Menu Item',
                      label: 'Menu Item',
                      display: true
                    }
                  ]}
                  onChange={data => {
                    const newNodeData = newNode;
                    if (
                      data.value === 'title' &&
                      newNodeData &&
                      newNodeData.children &&
                      newNodeData.children.length > 0
                    ) {
                      return toastManager.add(
                        'Can"t change type to title.Title node should not contain children',
                        {
                          appearance: 'info',
                          autoDismiss: false
                        }
                      );
                    }
                    newNodeData.type = data;

                    this.setState({
                      menuType: data.value,
                      newNode: newNodeData
                    });
                  }}
                />

                {newNode.type && newNode.type.value === 'menu_item' && (
                  <div className="my-2">
                    <ActionForms
                      formData={newNode.convertedAction}
                      onChange={data => {
                        if (Object.keys(data).length !== 1) {
                          const newNodeData = newNode;
                          newNodeData.action = data;
                          newNode.convertedAction = data;
                          this.setState({ newNode: newNodeData });
                        }
                      }}
                    />
                    <label>Menu Icon</label>
                    <MediaUpload
                      onSelect={media => {
                        const newNodeData = newNode;
                        const convertedIcon = {
                          label: 'Icon',
                          display: true,
                          display_value: media.url,
                          value: {
                            title:
                              media.title === undefined ? 'title' : media.title,
                            url: media.url,
                            meta:
                              media.meta === undefined
                                ? { width: 512, height: 512 }
                                : media.meta
                          }
                        };
                        newNodeData.icon = convertedIcon;
                        this.setState({ newNode: newNodeData });
                      }}
                      allowedTypes={['image']}
                      render={({ open }) => (
                        <div className="hover-pointer bg-light border py-3 mx-1 rounded text-center">
                          <div className="d-flex justify-content-center">
                            {newNode.icon && (
                              <img
                                src={newNode.icon.display_value}
                                alt="uploaded image"
                                className="mb-2"
                                style={{ maxHeight: 100, maxWidth: 100 }}
                              />
                            )}
                          </div>
                          <Button
                            className="btn btn-sm btn-outline-primary"
                            onClick={open}
                          >
                            {newNode.icon
                              ? 'Change icon'
                              : 'Open Media Library'}
                          </Button>
                        </div>
                      )}
                    />
                  </div>
                )}
                {!isEditing && (
                  <Button
                    className="btn btn-info btn-block mt-3"
                    disabled={isLoading}
                    onClick={() => {
                      const convertedNode = newNode;
                      // icon is needed in the api. Some app shows error when it does not contain icon
                      if (
                        convertedNode.type &&
                        convertedNode.type.value === 'menu_item' &&
                        convertedNode.icon === undefined
                      ) {
                        convertedNode.icon = '';
                      }
                      convertedNode.expanded = true;
                      this.addItem(convertedNode, parentId);
                    }}
                  >
                    Add
                  </Button>
                )}
                {isEditing && (
                  <Button
                    className="btn btn-info btn-block mt-3"
                    disabled={isLoading}
                    onClick={() => {
                      this.editItem(JSON.parse(JSON.stringify(newNode)));
                      // const convertedNode = newNode;
                      // convertedNode.expanded = true;
                      // this.addItem(convertedNode, parentId);
                    }}
                  >
                    Update
                  </Button>
                )}
              </div>
            )}
            <div className="config-components" />
          </div>
        </div>

        <Popover.Slot />
      </div>
    );
  }
}

export default withSelect((select, props, withSelectProps) => {
  const menuKey = select('appmaker/menu').getMenuKey();
  const user = select('appmaker/core').getUser();
  const project = select('appmaker/core').getCurrentProject();
  const menu = select('appmaker/menu').getMenu(menuKey);
  return { menu, languages: project.languages, user };
})(withToastManager(InAppPageMenuEditor));
