import React from 'react';
import { InspectorControls } from '@wordpress/block-editor';
import { PanelBody, RangeControl, ToggleControl } from '@wordpress/components';
import { __ } from '@wordpress/i18n';

export default props => {
  const {
    setAttributes,
    attributes,
    onChangeAttribute = false,
    children = false
  } = props;

  const {
    itemsPerLine,
    showViewMoreButton,
    showTitle,
    image,
    enableSplit,
    splitCount
  } = attributes;
  return (
    <InspectorControls>
      <PanelBody title="Grid Settings">
        <label>Columns</label>
        <RangeControl
          onChange={itemsPerLine => {
            if (onChangeAttribute !== false) {
              onChangeAttribute({ itemsPerLine });
            }
          }}
          value={itemsPerLine}
          min={1}
          max={5}
          required
        />
        <ToggleControl
          label="Enable Split"
          checked={enableSplit === 'Yes'}
          onChange={isChecked => {
            if (onChangeAttribute !== false)
              onChangeAttribute({
                enableSplit: isChecked ? 'Yes' : 'No'
              });
          }}
        />
        {enableSplit === 'Yes' && (
          <React.Fragment>
            <label>Items in page</label>
            <RangeControl
              onChange={splitCount => {
                if (onChangeAttribute !== false) {
                  onChangeAttribute({ splitCount });
                }
              }}
              value={splitCount}
              min={1}
              max={15}
              required
            />
          </React.Fragment>
        )}
        <ToggleControl
          label={__('Show View more button', 'newspack-blocks')}
          checked={showViewMoreButton}
          onChange={() => {
            if (onChangeAttribute !== false)
              onChangeAttribute({ showViewMoreButton: !showViewMoreButton });
          }}
        />
        <ToggleControl
          label={__('Show Grid Title', 'newspack-blocks')}
          checked={showTitle}
          onChange={() => {
            if (onChangeAttribute !== false)
              onChangeAttribute({ showTitle: !showTitle });
          }}
        />
        {children !== false && children}
      </PanelBody>
    </InspectorControls>
  );
};
