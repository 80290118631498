import React, { Component } from 'react';
import Select from 'react-select';
import Async from 'react-select/lib/Async';
import { select, withSelect } from '@wordpress/data';
import { find } from 'lodash';
import { fromAPI } from '../utils/convertor';
import { newApiProxy } from '../../../lib/apiFunctions';

class ActionSelect extends Component {
    constructor(props) {
        super(props);
    }
    componentWillReceiveProps(newProps) {
        this.promiseOptions('');
    }
    promiseOptions = input => {
        // NOTE Removed the api proxy from the store and makes this as api call
        return new Promise(resolve => {
            const queryParams = {};
            const { dataSource } = this.props;
            if (dataSource !== undefined && dataSource.link !== undefined && dataSource.store !== 'core') {
                let searchQuery = dataSource.link;
                if (dataSource.depend === true) {
                    searchQuery = input !== undefined && input.length !== 0 ? searchQuery + `&search=${input}` : searchQuery;
                }
                else {
                    searchQuery = input !== undefined && input.length !== 0 ? searchQuery + `?search=${input}` : searchQuery;
                }

                newApiProxy(this.props.projectId, searchQuery)
                    .then(value => {
                        let result = value.body;
                        if (dataSource.showAll && result !== undefined) {
                            result.push({ id: "", label: "All" })
                        }
                        return resolve(result)
                    })
                    .catch(error => {
                        // to avoid error
                        return resolve([])
                    })
            }

            if (dataSource && dataSource.store === 'core' && dataSource.name === 'inAppPageList') {
                return resolve(this.props.inAppPageList)
            }
        });
    };

    /**
     * Action SelectBox render. This will render with default options as params or provide dataSource as and argument to get data from @wordpress/data soruce
     * and searchItems function must be defind in the store
     * @param {*} props
     */
    render() {
        const { options, value, onChange } = this.props;
        if (options) {
            return (
                <Select
                    {...this.props}
                    value={find(options, { value: value })}
                    options={options}
                />
            );
        }

        return (

            <Async
                getOptionLabel={option => option.label}
                getOptionValue={option => option.id}
                defaultOptions
                name="test"
                loadOptions={this.promiseOptions}
                value={value}
                onChange={onChange}
            />
        );
    }
}


export default withSelect(select => {
    const inAppPageList = select('appmaker/in-app-page').getInAppPageList();
    return { inAppPageList };
})(ActionSelect)