import React, { useEffect, useState } from 'react';
import PreLoading from '../../../../components/dashboard/layout/PreLoading';
import productDetails from './productDetails.json';
import { newApiProxy } from '../../../../lib/apiFunctions';

export default ({ source = {}, projectId }) => {
  const { params: { id, label } = {} } = source;
  const [product, setProduct] = useState(productDetails);
  const [isLoading, setLoading] = useState(false);
  const getProductDetails = async id => {
    setLoading(true);
    try {
      const response = await newApiProxy(
        projectId,
        `prebuildProductScroller?search=${id}`
      );
      if (response && response.status === true && response.body) {
        setProduct(response.body);
      }
    } catch (error) {}
    setLoading(false);
  };
  useEffect(() => {
    if (id !== undefined) {
      getProductDetails(id);
    }
  }, [source]);
  if (isLoading) {
    return (
      <div>
        <PreLoading single />
      </div>
    );
  }
  return product.map(item => {
    return (
      <div className="product-card rounded shadow-sm p-2 mr-2" key={item.id}>
        <img width="100px" height="auto" alt="product" src={item.src} />
        <h6>{item.title}</h6>
        <p
          className="small-label-text"
          dangerouslySetInnerHTML={{ __html: item.price_display }}
        />
      </div>
    );
  });
};
