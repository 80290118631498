import React from 'react';
import WooCommerce from './woocommerce';
import WebApp from './webapp';
import WordPress from './wordpress';
import CustomApp from './customApp';
import Shopify from './shopify';

const SideBar = props => {
  const {
    template,
    appId,
    subscriptionExpired,
    toggleSideBar,
    otherProps,
    isAdmin
  } = props;
  const hideComponents = !isAdmin && subscriptionExpired;
  switch (template) {
    case 'woocommerce': {
      return (
        <WooCommerce
          appId={appId}
          isAdmin={isAdmin}
          subscriptionExpired={subscriptionExpired}
          toggleSideBar={toggleSideBar}
          {...otherProps}
          hideComponents={hideComponents}
        />
      );
    }
    case 'webapp': {
      return (
        <WebApp
          appId={appId}
          subscriptionExpired={subscriptionExpired}
          toggleSideBar={toggleSideBar}
          hideComponents={hideComponents}
        />
      );
    }
    case 'wordpress': {
      return (
        <WordPress
          appId={appId}
          isAdmin={isAdmin}
          subscriptionExpired={subscriptionExpired}
          toggleSideBar={toggleSideBar}
          {...otherProps}
          hideComponents={hideComponents}
        />
      );
    }
    case 'customapp': {
      return (
        <CustomApp
          appId={appId}
          subscriptionExpired={subscriptionExpired}
          toggleSideBar={toggleSideBar}
          {...otherProps}
          hideComponents={hideComponents}
        />
      );
    }
    case 'shopify': {
      return (
        <Shopify
          appId={appId}
          isAdmin={isAdmin}
          subscriptionExpired={subscriptionExpired}
          toggleSideBar={toggleSideBar}
          {...otherProps}
          hideComponents={hideComponents}
        />
      );
    }
    default: {
      return <div />;
    }
  }
};
export default SideBar;
