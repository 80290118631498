import axios from 'axios';
import * as ROUTES from '../constants/routes';

const { API_URL } = ROUTES;

/**
 *
 * @param {*} appId its app id not project id
 * @param {*} data // auhKey ( file ), issuerId, apiKey (Form data)
 */
export const uploadIosAuthKey = async ({
  projectId,
  appId,
  authKey,
  apiKey,
  issuerId
}) => {
  try {
    const data = new FormData();
    data.append('authKey', authKey);
    data.append('apiKey', apiKey);
    data.append('issuerId', issuerId);
    const url = `${API_URL}/v2/projects/${projectId}/upload-ios-auth-key/?app_id=${appId}`;
    const response = await axios.post(url, data, {
      withCredentials: true
    });
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 *
 * @param {*} appId its app id not project id
 * @param {Number} ProjectId
 */
export const iosCreateCertStatus = async (appId, projectId) => {
  try {
    const url = `${API_URL}/v2/projects/${projectId}/ios-create-cert-status/?app_id=${appId}`;
    const response = await axios.get(url, {
      withCredentials: true
    });
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 *
 * @param {*} appId its app id not project id
 */
export const buildIpaFile = async (appId, projectId) => {
  try {
    const url = `${API_URL}/v2/projects/${projectId}/build-ipa-file/?app_id=${appId}`;
    const response = await axios.get(url, {
      withCredentials: true
    });
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const uploadIpaFile = async (projectId, appId) => {
  try {
    // const url = `${BaseUrl}/projects/ios-ipa-upload/?app_id=${appId}`;
    const url = `${API_URL}/v2/projects/${projectId}/ios-ipa-upload/?app_id=${appId}`;
    const response = await axios.get(url, {
      withCredentials: true
    });
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 *
 * @param {*} appId its app id not project id
 */
export const ipaUploadStatus = async (projectId, appId) => {
  try {
    const url = `${API_URL}/v2/projects/${projectId}/ios-ipa-upload-status/?app_id=${appId}`;
    const response = await axios.get(url, {
      withCredentials: true
    });
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};
