import React from 'react';
import { __ } from '@wordpress/i18n';
import {
  InnerBlocks,
  InspectorControls,
  RichText
} from '@wordpress/block-editor';
import { PanelBody, ToggleControl } from '@wordpress/components';
import { withSelect } from '@wordpress/data';
import ActionSelect from '../../../hooks/action/components/NewActionSelect';
import { extractTextFromHTML } from '../../helper';
import ProductScrollerImages from './helper/imageList';

const ShopifyProductScroller = props => {
  const { projectId } = props;

  const { attributes, setAttributes, className, action = () => {} } = props;
  const {
    source = {},
    title,
    showTitle,
    showViewMoreButton,
    ctaText
  } = attributes;
  const { params = {} } = source;
  return (
    <div className={className}>
      <div className="block-title-part d-flex p-2 justify-content-between">
        <div className="block-title my-auto">
          {showTitle && (
            <RichText
              allowedFormats={[]}
              value={title}
              withoutInteractiveFormatting
              onChange={text => {
                setAttributes({ title: extractTextFromHTML(text) });
              }}
              placeholder="Product Scroller title"
            />
          )}
        </div>
        <div className="view-more-button">
          {showViewMoreButton && (
            <div className="btn btn-light btn-sm">
              <RichText
                allowedFormats={[]}
                value={ctaText}
                withoutInteractiveFormatting
                onChange={ctaTexts => {
                  setAttributes({ ctaText: extractTextFromHTML(ctaTexts) });
                }}
                placeholder="View More Button"
              />
            </div>
          )}
        </div>
      </div>
      <div className="product-scroller-container">
        <ProductScrollerImages source={source} projectId={projectId} />
      </div>
      <InspectorControls>
        <PanelBody>
          <ToggleControl
            label={__('Show Title', 'newspack-blocks')}
            checked={showTitle}
            onChange={() => {
              setAttributes({ showTitle: !showTitle });
            }}
          />
          <ToggleControl
            label={__('Show View more button', 'newspack-blocks')}
            checked={showViewMoreButton}
            onChange={() => {
              setAttributes({ showViewMoreButton: !showViewMoreButton });
            }}
          />
          Choose Product Scroller
          <ActionSelect
            value={params}
            projectId={projectId}
            dataSource={{ link: 'collections' }}
            onChange={value => {
              setAttributes({
                source: {
                  type: 'collection',
                  params: value
                }
              });
              action(value);
            }}
          />
        </PanelBody>
      </InspectorControls>
    </div>
  );
};

export default withSelect((select, props) => {
  const project = select('appmaker/core').getCurrentProject();
  const url = project.meta.URL;
  const apiKey = project.meta.API_KEY;
  return { url, apiKey, projectId: project.id };
})(ShopifyProductScroller);
