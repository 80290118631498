import React, { useState } from 'react';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { withSelect } from '@wordpress/data';
import { useToasts } from 'react-toast-notifications';
import { MediaUpload } from '@wordpress/block-editor';
import BlockAction from '../customizeApp/components/configComponents/BlockAction';
import ActionFrom from '../../../hooks/action/components/ActionForm';
import { verifyAction } from '../../InAppPageMenuEditor/verifyAction';
import { pushNotification } from '../../../lib/apiFunctions';
import { trackEvent } from '../../../utils/tracker';
import {
  convertActionToWidgets,
  convertActionToBlock
} from '../../../utils/converter/helper/action';
import PushConfigurePage from './pushConfigurePage';

const pushConverter = action => {
  switch (action.action) {
    case 'OPEN_PRODUCT_TAG': {
      return {
        action: {
          value: 'LIST_PRODUCT',
          display_value: 'Product Tag',
          label: 'action',
          display: 'true'
        },
        action_value: {
          value: action.OPEN_PRODUCT_TAG_PRODUCT_ID.id,
          display_value: action.OPEN_PRODUCT_TAG_PRODUCT_ID.label,
          label: 'Choose Product Tag',
          display: true
        },
        paramTag: 'tag'
      };
    }
    default: {
      return convertActionToWidgets(action);
    }
  }
};

const PushNotificationForm = props => {
  const { addToast } = useToasts();
  const {
    projectId,
    isPushNotificationPaid,
    user = {},
    syncPluginConfigured = () => {}
  } = props;
  const [isLoading, setLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [action, setAction] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [pushConfigured, setPushConfigured] = useState(props.pushConfigured);

  const afterPushConfigurationDone = value => {
    if (value) {
      setPushConfigured(true);
      syncPluginConfigured();
    }
  };
  // NOTE  Used in old dashboard to set the corresponding params
  const getActionParams = (action, action_value, paramTag) => {
    let params = {};
    switch (action) {
      case 'OPEN_URL':
      case 'OPEN_IN_WEB_VIEW': {
        params = { url: action_value };
        break;
      }
      case 'LIST_POST': {
        params = { categories: action_value };
        break;
      }
      case 'LIST_PRODUCT': {
        if (paramTag) {
          params = { [paramTag]: action_value };
          break;
        }
        params = { category: action_value };
        break;
      }
      case 'OPEN_IN_APP_PAGE': {
        params = { id: action_value };
        break;
      }
      case 'OPEN_POST': {
        params = { id: action_value };
        break;
      }
      case 'OPEN_PRODUCT': {
        params = { id: action_value };
        break;
      }
      case 'LIST_PRODUCT_TAG': {
        params = { id: action_value };
        break;
      }
      default: {
        params = {};
        // to fix push for shopify action
        if (action_value && action_value.length > 0) {
          params = { id: action_value };
        }
        break;
      }
    }
    return params;
  };
  // NOTE  Function to send push notification
  const sendPushNotification = async () => {
    setLoading(true);
    if (title === '') {
      setLoading(false);
      return addToast('Title cannot be empty', {
        appearance: 'warning',
        autoDismiss: true
      });
    }
    if (message === '') {
      setLoading(false);
      return addToast('Message cannot be empty', {
        appearance: 'warning',
        autoDismiss: true
      });
    }
    // NOTE  Verify action is used for finding if the action is selected or not with valid child
    if (action === '' || verifyAction(action) === undefined) {
      setLoading(false);
      return addToast('Action cannot be empty', {
        appearance: 'warning',
        autoDismiss: true
      });
    }
    // NOTE  convertedAction To convert the action name in new dashboard to old version name
    const convertedAction = pushConverter(action);
    const convertedParams = getActionParams(
      convertedAction.action.value,
      convertedAction.action_value.value,
      convertedAction.paramTag
    );
    const convertedData = {
      title,
      message,
      action_type: convertedAction.action.value,
      action_params: convertedParams
    };
    if (imageUrl !== '') {
      convertedData.image = imageUrl;
    }
    try {
      const response = await pushNotification(projectId, convertedData);
      if (response.status === true) {
        try {
          trackEvent(
            'push_notification_send',
            {
              project_id: projectId,
              action: convertedAction.action.value,
              action_value: convertedAction.action_value.value,
              hasImage: imageUrl !== '',
              email: user.email
            },
            { mautic: true }
          );
        } catch (error) {}

        addToast('Push Notification send', {
          appearance: 'success',
          autoDismiss: true
        });
        setAction('');
        setTitle('');
        setMessage('');
        setImageUrl('');
      } else {
        try {
          trackEvent('push_notification_send_error', {
            project_id: projectId,
            action: convertedAction.action.value,
            action_value: convertedAction.action_value.value,
            hasImage: imageUrl !== '',
            email: user.email
          });
        } catch (error) {}
        addToast(response.errorMessage, {
          appearance: 'error',
          autoDismiss: true
        });
      }
    } catch (error) {
      addToast('Something went wrong', {
        appearance: 'error',
        autoDismiss: true
      });
    }
    setLoading(false);
  };
  return (
    <div className="row">
      <Helmet>
        <title>Push notification - Appmaker dashboard </title>
      </Helmet>
      <div className="col-md-8">
        <div className="bg-white p-4 rounded-lg dashboard-box">
          {/* state to check weather configured or not */}
          {pushConfigured === false ? (
            <PushConfigurePage
              {...props}
              afterPushConfigurationDone={afterPushConfigurationDone}
            />
          ) : (
            <React.Fragment>
              <div className="border-bottom border-light mb-4">
                <h4 className="mb-0 text-primary">Push Notification</h4>
                <label htmlFor="" className="small-label-text text-secondary">
                  Send your customized message with action to all your app
                  users.
                </label>
              </div>
              <Form>
                <FormGroup>
                  <Label for="notificationTitle">Notification Title</Label>
                  <Input
                    type="text"
                    name="title"
                    value={title}
                    id="notificationTitle"
                    placeholder="Awesome deal is here.."
                    onChange={event => {
                      setTitle(event.target.value);
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="notificationMessage">Message</Label>
                  <Input
                    type="textarea"
                    name="text"
                    value={message}
                    id="notificationMessage"
                    onChange={event => {
                      setMessage(event.target.value);
                    }}
                  />
                </FormGroup>
                <ActionFrom
                  formData={action}
                  onChange={data => {
                    // Checking the condition to avoid the loop of setState in render in initial it automatically re-render many times
                    if (
                      data.params !== undefined &&
                      data.action !== undefined
                    ) {
                      setAction(data);
                    }
                  }}
                />
                {isPushNotificationPaid === true && (
                  <MediaUpload
                    onSelect={media => {
                      setImageUrl(media.url);
                    }}
                    allowedTypes={['image']}
                    render={({ open }) => (
                      <div>
                        <label>Notification Image</label>
                        <div className="hover-pointer bg-light border py-3 rounded text-center">
                          <div className="d-flex justify-content-center mb-2">
                            {imageUrl !== '' && (
                              <img
                                src={imageUrl}
                                alt="uploaded image"
                                style={{ maxHeight: 100, maxWidth: 100 }}
                              />
                            )}
                          </div>
                          <Button
                            className="btn btn-sm btn-outline-primary"
                            onClick={open}
                          >
                            Open Media Library
                          </Button>
                        </div>
                      </div>
                    )}
                  />
                )}

                <div className="text-right mt-5">
                  {/* <Button color="link" className="mr-3 text-dark">
              cancel
            </Button> */}
                  <Button
                    color="primary"
                    className="px-5"
                    disabled={isLoading}
                    onClick={() => {
                      sendPushNotification();
                    }}
                  >
                    {isLoading ? 'Sending' : 'Send'}
                  </Button>
                </div>
              </Form>
            </React.Fragment>
          )}
        </div>
      </div>
      <div className="col-md-4">
        {/* <div className="bg-white p-4 rounded-lg dashboard-box">
          <h6>
            <span className="text-primary mr-2">
              <i className="fas fa-question-circle" />
            </span>
{' '}
            Need Help
          </h6>

          <p className="small-label-text">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Consectetur aliquid maiores maxime voluptatem atque error iste.
            Cumque magni mollitia fugit nesciunt, at quibusdam quis tempora
            commodi qui. Aliquam, delectus itaque?
          </p>
          <a href="#">Learn more</a>
              </div> */}
      </div>
    </div>
  );
};

export default withSelect((select, props, dispatch) => {
  const project = select('appmaker/core').getCurrentProject();
  const user = select('appmaker/core').getUser();
  const { feature = {}, configure = {} } = project;
  const { enablePushWithImageNotification = false } = feature;
  const { pushConfigured = false } = configure;

  // let pushConfigured = false;
  // if (project !== undefined) {
  //   pushConfigured =
  //     project.meta.FCM_AUTH_KEY !== undefined &&
  //     project.meta.FCM_AUTH_KEY !== '';
  // }
  /**
   * Avoid reloading after plugin configured completed
   */
  const syncPluginConfigured = async () => {
    await dispatch.dispatch('appmaker/core').setCurrentProject({
      ...project,
      configure: { pushConfigured: true }
    });
  };
  return {
    projectId: project.id,
    isPushNotificationPaid: enablePushWithImageNotification,
    pushConfigured,
    user,
    syncPluginConfigured
  };
})(PushNotificationForm);
