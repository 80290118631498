import React from 'react';
import { withSelect } from '@wordpress/data';
import { momentDate } from '../../../lib/helper';
import * as ROUTES from '../../../constants/routes';
import SupportSideBar from '../MessageScreens/supportSideDrawer';
import PluginUninstalledWarning from '../MessageScreens/PluginInstallWarning';
import CONSTANT from '../../../lib/constants';

const NavbarTop = ({
  project: { endDate, paymentStatus },
  isExpired,
  imgSrc,
  userName,
  logout,
  pluginResponse = true,
  loginProvider
}) => {
  const date = momentDate(endDate);
  // const convertedDate = `${date.date}-${date.month}-${date.year}`;
  let SubscriptionMessage = '';
  if (paymentStatus == '9') {
    SubscriptionMessage = (
      <a className="nav-link text-success" href="#">
        Trial expires 
{' '}
{date}
      </a>
    );
  } else if (
    paymentStatus == CONSTANT.PROJECT_PAYMENT_EXPIRED ||
    paymentStatus == CONSTANT.PROJECT_PAYMENT_EXPIRED_COMPLETE
  ) {
    SubscriptionMessage = (
      <a className="nav-link text-danger" href="#">
        Subscription expired 
{' '}
{date}
      </a>
    );
  }
  return (
    <nav className="navbar px-0">
      <div className="flex-grow-1 subscription-message-ontopbar">
        <ul className="navbar-nav">
          <li className="nav-item">
            {imgSrc ? (
              <img src={imgSrc} width="150px" alt="app-logo" />
            ) : (
              SubscriptionMessage
            )}
          </li>
        </ul>
      </div>
      {loginProvider !== 'shopify' && (
        <div className="dropdown d-inline-flex">
          {!pluginResponse && <PluginUninstalledWarning />}
          <SupportSideBar />

          <a
            className="nav-link py-0 dropdown-toggle text-dark cursor-pointer"
            id="navbarDropdownMenuLink"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <img
              src="https://storage.googleapis.com/stateless-appmaker-pages-wp/2019/05/6bae3f87-b1bbec499a0d66e5403480e8cda1bcbe.png"
              height="30"
              width="30"
              alt="user"
              className="rounded-circle mr-2"
            />
            {userName}
          </a>
          <div
            className="dropdown-menu dropdown-menu-right border-0 shadow"
            aria-labelledby="navbarDropdownMenuLink"
          >
            <a href={ROUTES.MANAGE} className="dropdown-item">
              Manage apps
            </a>
            <a className="dropdown-item" href={ROUTES.ACCOUNT_SETTINGS}>
              Account settings
            </a>
            <button
              type="button"
              className="dropdown-item"
              onClick={() => {
                logout().then(() => {
                  window.location.href = 'https://appmaker.xyz/logout';
                });
              }}
            >
              Logout
            </button>
          </div>
        </div>
      )}
    </nav>
  );
};

export default withSelect((select, ownProps, dispatch) => {
  const logout = () => {
    return dispatch.dispatch('appmaker/core').logout();
  };
  const user = select('appmaker/core').getUser();
  const { loginProvider } = user || {};
  return { logout, loginProvider };
})(NavbarTop);
