import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import { uploadFile } from '../../../../../../lib/updateAppApi';

export default props => {
  const {
    projectId,
    addToast = () => {},
    onChange = () => {},
    value,
    schema = {}
  } = props;
  const { tagName = '', fileType, skipFileNameChange = false } = schema;
  const [isLoading, setLoading] = useState(false);
  const onDrop = async files => {
    try {
      if (files && files[0]) {
        setLoading(true);
        const data = new FormData();
        data.append('file', files[0]);
        const response = await uploadFile(
          projectId,
          data,
          tagName,
          `skipFileNameChange=${skipFileNameChange}`
        );
        if (response.status && response.body && response.body.fileName) {
          addToast('File upload finished', {
            appearance: 'success',
            autoDismiss: true
          });
          onChange(response.body.fileName);
          return setLoading(false);
        }
        addToast('Something went wrong', {
          appearance: 'error',
          autoDismiss: true
        });
      } else {
        addToast('Something went wrong', {
          appearance: 'error',
          autoDismiss: true
        });
      }
    } catch (error) {
      addToast(error.message || 'Something went wrong', {
        appearance: 'error',
        autoDismiss: true
      });
    }
    setLoading(false);
  };
  if (isLoading) {
    return (
      <React.Fragment>
        <div>Uploading ....</div>
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      {value && (
        <p>
          You've uploaded the file. Upload again (filename:
          {value})
        </p>
      )}
      <Dropzone onDrop={onDrop} multiple={false} accept={fileType} style={{}}>
        {({ getRootProps, getInputProps }) => (
          <div
            {...getRootProps({
              className:
                'upload-new text-center cursor-pointer py-2 px-1 rounded border-primary bg-light mr-2 mb-2'
            })}
          >
            <div className="text-primary h-100">
              <div className="mt-4">
                <i className="fas fa-upload" />
                <br />
                <input {...getInputProps()} />
                Click to Upload Media
              </div>
            </div>
          </div>
        )}
      </Dropzone>
    </React.Fragment>
  );
};
