import { dispatch } from '@wordpress/data';
// import { REDUCER_KEY } from './store/name';
const REDUCER_KEY = 'shopify';
const coreActions = [
  {
    id: 'OPEN_IN_WEBVIEW',
    title: 'Open WebView',
    template: 'common',
    subAction: {
      id: 'url',
      title: 'Choose URL',
      type: 'text',
      subAction: {
        string: true,
        id: 'extraStyle',
        title: 'Extra style',
        type: 'text',
        defaultValue: ''
      }
    }
  },
  {
    id: 'OPEN_URL',
    title: 'Open URL',
    template: 'common',
    subAction: {
      id: 'url',
      title: 'Choose URL',
      type: 'text'
    }
  },
  {
    id: 'OPEN_PRODUCT_LIST',
    title: 'Open all products'
  },
  {
    id: 'OPEN_PRODUCT',
    title: 'Open product',
    subAction: {
      id: 'PRODUCT_ID',
      title: 'Choose product',
      type: 'select',
      dataSource: {
        kind: 'root',
        name: 'products',
        link: 'products',
        store: REDUCER_KEY
      }
    }
  },
  {
    id: 'OPEN_COLLECTION',
    title: 'Open products by collections',
    subAction: {
      id: 'ID',
      title: 'Choose collection',
      type: 'select',
      dataSource: {
        kind: 'root',
        name: 'collections',
        link: 'collections',
        store: REDUCER_KEY
      },
      subAction: {
        string: true,
        id: 'TITLE',
        title: 'Page title',
        type: 'text',
        defaultValue: ''
      }
    }
  },
  {
    id: 'OPEN_BY_TAG',
    title: 'Open products by tag',
    subAction: {
      id: 'ID',
      title: 'Choose tag',
      type: 'select-multi',
      typeConfig: {
        isMulti: true,
        isCreatable: true
      },
      dataSource: {
        kind: 'root',
        name: 'tags',
        link: 'tags',
        store: REDUCER_KEY
      },
      subAction: {
        string: true,
        id: 'TITLE',
        title: 'Page title',
        type: 'text',
        defaultValue: 'My Products'
      }
    }
  },
  { id: 'OPEN_SEARCH', title: 'Open search', template: 'shopify' },
  {
    id: 'OPEN_BLOG_LIST',
    title: 'Open Blog',
    subAction: {
      id: 'ID',
      title: 'Choose blog',
      type: 'select',
      dataSource: {
        kind: 'root',
        name: 'blogs',
        link: 'blogs',
        store: REDUCER_KEY
      }
    }
  },
  {
    id: 'OPEN_PAGE',
    title: 'Open Page',
    subAction: {
      id: 'ID',
      title: 'Choose page',
      type: 'select',
      dataSource: {
        kind: 'root',
        name: 'pages',
        link: 'pages',
        store: REDUCER_KEY
      }
    }
  },
  { id: 'NO_ACTION', title: 'No Action', template: 'shopify' }
];
// {
//   id: 'OPEN_PUSH_HISTORY',
//     title: 'Open push notification history',
//       template: 'woocommerce'
// }
export function registerActionType(actionType) {
  return dispatch('core/actions').addActionTypes(actionType);
}
export function registerActionTypes() {
  coreActions.map(registerActionType);
}
