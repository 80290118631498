import React, { Component } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

// eslint-disable-next-line react/prefer-stateless-function
class ApiCredential extends Component {
  state = {
    projectIdCopied: false,
    apiKeyCopied: false,
    apiSecretCopied: false,
    packageNameCopied: false
  };
  render() {
    return (
      <div className="row mb-3">
        <div className="col-12 pt-4">
          <div className="bg-white p-4 rounded-lg dashboard-box">
            <h5>API Credentials</h5>
            <div className="row my-2">
              <div className="col-md-6 col-12 my-1">
                <label htmlFor="project_id">Project Id</label>
                <div className="col input-group p-0">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Project Id"
                    defaultValue={this.props.projectId}
                    id="project_id"
                    disabled
                  />
                  <div className="input-group-append">
                    <CopyToClipboard
                      text={this.props.projectId}
                      onCopy={() => {
                        this.setState({ projectIdCopied: true });
                        setTimeout(() => {
                          this.setState({ projectIdCopied: false });
                        }, 3000);
                      }}
                    >
                      <button
                        className="btn btn-dark border-0"
                        type="button"
                        id="copy_project_id"
                      >
                        <span>
                          <i className="fas fa-clipboard mr-2"></i>
                        </span>
                        {this.state.projectIdCopied ? 'Copied' : 'Copy'}
                      </button>
                    </CopyToClipboard>
                  </div>
                </div>
              </div>
              {this.props.apiKey && this.props.apiKey !== "" && <div className="col-md-6 col-12 my-1">
                <label htmlFor="api_key">API Key</label>
                <div className="col input-group p-0">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="API key"
                    id="api_key"
                    defaultValue={this.props.apiKey}
                    disabled
                  />
                  <div className="input-group-append">
                    <CopyToClipboard
                      text={this.props.apiKey}
                      onCopy={() => {
                        this.setState({ apiKeyCopied: true });
                        setTimeout(() => {
                          this.setState({ apiKeyCopied: false });
                        }, 3000);
                      }}
                    >
                      <button
                        className="btn btn-dark border-0"
                        type="button"
                        id="copy_project_id"
                      >
                        <span>
                          <i className="fas fa-clipboard mr-2"></i>
                        </span>
                        {this.state.apiKeyCopied ? 'Copied' : 'Copy'}
                      </button>
                    </CopyToClipboard>
                  </div>
                </div>
              </div>}
            </div>
            <div className="row mb-2">
              <div className="col-md-6 col-12 my-1">
                <label htmlFor="package_name">Package Name</label>
                <div className="col input-group p-0">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="First name"
                    id="package_name"
                    defaultValue={this.props.packageName}
                    disabled
                  />
                  <div className="input-group-append">
                    <CopyToClipboard
                      text={this.props.packageName}
                      onCopy={() => {
                        this.setState({ packageNameCopied: true });
                        setTimeout(() => {
                          this.setState({ packageNameCopied: false });
                        }, 3000);
                      }}
                    >
                      <button
                        className="btn btn-dark border-0"
                        type="button"
                        id="copy_project_id"
                      >
                        <span>
                          <i className="fas fa-clipboard mr-2"></i>
                        </span>
                        {this.state.packageNameCopied ? 'Copied' : 'Copy'}
                      </button>
                    </CopyToClipboard>
                  </div>
                </div>
              </div>
              {this.props.apiSecret
                && this.props.apiSecret !== ""
                && this.props.template !== 'shopify'
                && (<div className="col-md-6 col-12 my-1">
                  <label htmlFor="api_secret d-block">API Secret</label>
                  <div className="col input-group p-0">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="API secret"
                      id="api_secret"
                      defaultValue={this.props.apiSecret}
                      disabled
                    />
                    <div className="input-group-append">
                      <CopyToClipboard
                        text={this.props.apiSecret}
                        onCopy={() => {
                          this.setState({ apiSecret: true });
                          setTimeout(() => {
                            this.setState({ apiSecret: false });
                          }, 3000);
                        }}
                      >
                        <button
                          className="btn btn-dark border-0"
                          type="button"
                          id="copy_project_id"
                        >
                          <span>
                            <i className="fas fa-clipboard mr-2"></i>
                          </span>
                          {this.state.apiSecret ? 'Copied' : 'Copy'}
                        </button>
                      </CopyToClipboard>
                    </div>
                  </div>
                </div>)
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ApiCredential;
