import { select } from '@wordpress/data';
import React from 'react';
import { Link } from 'react-router-dom';
import { trackEvent } from '../../../../utils/tracker';
import { UpdateIcon } from '../Icons';

const UpdateApp = props => {
  const { subscriptionExpired } = props;
  const project = select('appmaker/core').getCurrentProject();

  return (
    <li className="nav-item">
      {/* <NavLink
            to={ROUTES.linkToUpdateApp()}
            activeClassName="active"
            className="vertical-nav-hover nav-link text-dark rounded-lg text-decoration-none"
          >
            <UpdateIcon fill="#202020" />
            Update App
          </NavLink> */}
      {!subscriptionExpired && (
        <a
          className="vertical-nav-hover nav-link text-dark rounded-lg text-decoration-none"
          href={props.link}
          target="_blank"
          activeClassName="active"
          onClick={() => {
            trackEvent('update_app_clicked', { project_id: project.id });
            console.log('update-app');
          }}
        >
          <UpdateIcon fill="#202020" />
          Update App
        </a>
      )}
      {subscriptionExpired && (
        <Link
          to={`/apps/${props.appId}`}
          className="vertical-nav-hover nav-link text-dark rounded-lg text-decoration-none"
        >
          <UpdateIcon fill="#202020" />
          Update App
        </Link>
      )}
    </li>
  );
};
export default UpdateApp;
