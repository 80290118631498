export default {
  starter: {
    planName: 'starter',
    revenueDisc: 'Upto $10k revenue through app. You will be auto-converted to $29/mo plan + 2.5% once you cross $10000 app sales.',
    perfectFor: 'Small business in starting stage.',
    skipMonthly: true,
    pricing: {
      yearly: {
        default: {
          price: 99,
          currencySymbol: '$',
          chargebeePlanId: {
            usd: 'woocommerce-starter-new',
          }
        },
        region_1: {
          price: 199,
          currencySymbol: '$',
          chargebeePlanId: {
            usd: 'woocommerce-starter-region-1',
          }
        },
        india: {
          price: 9999,
          currencySymbol: '₹',
          chargebeePlanId: {
            inr: 'woocommerce-starter-inr'
          }
        }
      },
    }
  },
  growth: {
    id: 'growth',
    planName: 'growth',
    revenueDisc: '+1.5% Revenue through app',
    perfectFor: 'For business in the growth stage.',
    pricing: {
      yearly: {
        default: {
          price: 66,
          currencySymbol: '$',
          chargebeePlanId: {
            usd: 'woocommerce-growth-yearly',
          }
        },
        india: {
          price: 4999,
          currencySymbol: '₹',
          chargebeePlanId: {
            inr: 'woocommerce-growth-yearly-inr',
          }
        }
      },
      monthly: {
        default: {
          price: 79,
          currencySymbol: '$',
          chargebeePlanId: {
            usd: 'woocommerce-growth',
          }
        },
        india: {
          price: 5999,
          currencySymbol: '₹',
          chargebeePlanId: {
            inr: 'woocommerce-growth-inr',
          }
        }
      }
    },
    yearlyPriceInMonth: true
  },
  scale: {
    id: 'scale',
    planName: 'scale',
    revenueDisc: '+0.5% Revenue through app',
    perfectFor: 'For business in the scale stage.',
    pricing: {
      yearly: {
        default: {
          price: 208,
          currencySymbol: '$',
          chargebeePlanId: {
            usd: 'woocommerce-scale-yearly-new',
          }
        },
        india: {
          price: 15833,
          currencySymbol: '₹',
          chargebeePlanId: {
            inr: 'woocommerce-scale-yearly-inr',
          }
        }
      },
      monthly: {
        default: {
          price: 249,
          currencySymbol: '$',
          chargebeePlanId: {
            usd: 'woocommerce-scale',
          }
        },
        india: {
          price: 18999,
          currencySymbol: '₹',
          chargebeePlanId: {
            inr: 'woocommerce-scale-inr',
          }
        }
      }
    },
    yearlyPriceInMonth: true
  }
};
