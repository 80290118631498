import React from 'react';
import UpdateApp from '../components/updateAppShopify';
import * as ROUTES from '../../../../constants/routes';
import PushNotification from '../components/pushNotification';
import Subscription from '../components/subscription';
import CustomizeApp from '../components/customizeApp';
import Settings from '../components/Settings';
import CustomerData from '../components/CustomerData';

const Shopify = props => {
  const {
    appId,
    subscriptionExpired,
    toggleSideBar,
    history,
    nameChanged,
    hideComponents,
    isAdmin = false
  } = props;
  return (
    <React.Fragment>
      {!hideComponents && (
        <React.Fragment>
          <CustomizeApp
            subscriptionExpired={subscriptionExpired}
            history={history}
            toggleSideBar={toggleSideBar}
            nameChanged={nameChanged}
          />
          <UpdateApp
            appId={appId}
            link={ROUTES.linkToUpdateApp()}
            subscriptionExpired={subscriptionExpired}
          />
          <PushNotification
            onClick={toggleSideBar}
            link={ROUTES.linkToPushNotification()}
          />
          {/* {isAdmin && (
            <Settings
              link={`${ROUTES.linkToSettings()}`}
              onClick={toggleSideBar}
            />
          )} */}
        </React.Fragment>
      )}
      {/* <Subscription
        link={ROUTES.linkToSubscription()}
        onClick={toggleSideBar}
      /> */}
      {isAdmin && (
        <React.Fragment>
          {' '}
          <CustomerData
            link={`${ROUTES.linkToCustomerData()}`}
            onClick={toggleSideBar}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default Shopify;
