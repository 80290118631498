import React, { useState, useEffect } from 'react';
import { useToasts } from 'react-toast-notifications';
import { Spinner, Button } from 'reactstrap';
import {
  createGitlabTicket,
  checkOAuth,
  loginWithGitlab
} from '../../lib/gitlabAutomationApi';
import TicketModal from './Components/TicketModal';
import OAuth from './Components/OAuth';

export default ({ buttonLabel, className, projectId }) => {
  const { addToast } = useToasts();
  const [accessToken, setAccessToken] = useState(false);
  const [modal, setModal] = useState(false);
  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState(null);
  const [labels, setLabels] = useState(null);
  const [issueOrFeature, setIssueOrFeature] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [mainLoader, setMainLoader] = useState(false);

  const toggle = () => setModal(!modal);
  const createTicket = async () => {
    setLoading(true);
    if (
      title === null ||
      description === null ||
      title.length === 0 ||
      description.length === 0
    ) {
      setLoading(false);
      return addToast('Fill the form', {
        appearance: 'error',
        autoDismiss: true
      });
    }
    try {
      const response = await createGitlabTicket(projectId, {
        title,
        description,
        labels,
        issueOrFeature,
        accessToken
      });

      if (response.status) {
        addToast('Ticket created', {
          appearance: 'success',
          autoDismiss: true
        });
        setTitle(null);
        setDescription(null);
        setLabels(null);
        toggle();
      } else {
        addToast('Something went wrong', {
          appearance: 'error',
          autoDismiss: true
        });
      }
    } catch (error) {
      addToast('Something went wrong', {
        appearance: 'error',
        autoDismiss: true
      });
    }
    setLoading(false);
  };
  const fetchData = async () => {
    setMainLoader(true);
    try {
      const response = await checkOAuth(projectId);
      if (response.status && response.body && response.body.token) {
        setAccessToken(response.body.token);
        setMainLoader(false);
      } else {
        setAccessToken(false);
        setMainLoader(false);
      }
    } catch (error) {
      setMainLoader(false);
      return addToast(
        error.message || 'Something went wrong ! Issue in OAuth status ',
        {
          appearance: 'error',
          autoDismiss: true
        }
      );
    }
  };
  const logout = () => {
    setAccessToken(false);
  };

  useEffect(() => {
    fetchData();
  }, []);
  if (mainLoader) {
    return <Spinner color="success" size="sm" />;
  }
  return (
    <div>
      {accessToken && (
        <TicketModal
          buttonLabel={buttonLabel}
          className={className}
          isLoading={isLoading}
          createTicket={createTicket}
          toggle={toggle}
          title={title}
          setTitle={setTitle}
          description={description}
          setDescription={setDescription}
          labels={labels}
          setLabels={setLabels}
          issueOrFeature={issueOrFeature}
          setIssueOrFeature={setIssueOrFeature}
          modal={modal}
        />
      )}
      {!accessToken && (
        <OAuth
          buttonLabel="Sign in to create gitlab ticket"
          className={className}
          loginUrl={loginWithGitlab(projectId)}
        />
      )}

      <Button className="mt-2" type="button" onClick={logout}>
        Logout Gitlab
      </Button>
    </div>
  );
};
