import React from 'react';
import {
  BrowserRouter as Router,
  Link,
  Route,
  Switch,
  NavLink
} from 'react-router-dom';
import Builder from '../Builder';
import * as ROUTES from '../../../../constants/routes';

export default ({
  projectId,
  data = {},
  onChangeMaster = () => {},
  formData,
  onSubmit = () => {},
  addToast = () => {},
  footerItem,
  isAdmin = false,
  updateDefaultValues
}) => {
  const generateTabs = () => {
    return Object.keys(data).map(tab => {
      if (!data[tab].adminTab || isAdmin) {
        return (
          <li className="py-2 mb-2">
            <NavLink
              activeClassName="settings-active-nav font-weight-bold"
              className="text-dark text-decoration-none"
              to={`/apps/${projectId}${ROUTES.UPDATE_APP_NEW_}${data[tab].path}`}
            >
              {data[tab].title}
            </NavLink>
          </li>
        );
      }
      return null;
    });
  };

  const generateBuilder = () => {
    return Object.keys(data).map(tab => {
      return (
        <React.Fragment>
          <Route
            path={`/apps/${projectId}${ROUTES.UPDATE_APP_NEW_}${data[tab].path}`}
            render={prop => (
              <Builder
                projectId={projectId}
                addToast={addToast}
                formData={formData}
                name={data[tab].name}
                schema={data[tab].schema}
                uiSchema={data[tab].uiSchema}
                translationTab={data[tab].translationTab}
                onChangeMaster={onChangeMaster}
                onSubmit={onSubmit}
                updateDefaultValues={updateDefaultValues}
              />
            )}
          />
        </React.Fragment>
      );
    });
  };
  return (
    <div className="row wrap">
      <Router>
        <div className="col-md-3 bg-white">
          <React.Fragment>
            <nav className="mr-0">
              <div className="border-bottom text-dark pt-3 mb-2">
                <h5>Update App</h5>
              </div>
              <ul className="list-unstyled">
                <li>{generateTabs()}</li>
              </ul>
            </nav>
          </React.Fragment>
        </div>
        <div className="col-md-9 wrap overflow-auto">
          {generateBuilder()}
          {footerItem}
        </div>
      </Router>
    </div>
  );
};
