import React from 'react';

export default ({
  _defaultLanguageCode,
  value,
  setDefaultLanguage = () => {},
  editButtonOnClick = () => {},
  options
}) => {
  // to get label from options. values doesnot contain label of language.
  const languageValue =
    value && value.languages
      ? Object.keys(value.languages).map(item =>
          options.find(opt => opt.value === item)
        )
      : [];
  return languageValue.map(item => {
    if (item !== undefined) {
      const isDefault = _defaultLanguageCode === item.value;
      return (
        <React.Fragment>
          {/* <br />
            <div>{availableLanguage.label}</div>
            <button
              className="btn btn-sm btn-success"
              type="button"
              key={availableLanguage.value}
              onClick={() => {
                editButtonOnClick(availableLanguage.value);
              }}
            >
              Edit
            </button>
            <button
              className="pl-2"
              type="button"
              onClick={() => {
                setDefaultLanguage(availableLanguage.value);
              }}
            >
              Set as default language
            </button> */}
          <li className="list-group-item d-flex justify-content-between align-items-center">
            <span className="d-flex align-items-center">
              <h5 className="mb-0">{item.label}</h5>
              {isDefault && (
                <span className="badge badge-pill badge-success ml-2">
                  Default
                </span>
              )}
            </span>
            <span>
              {!isDefault && (
                <button
                  key={item.value}
                  type="button"
                  className="btn btn-sm btn-outline-primary mr-2"
                  onClick={() => {
                    setDefaultLanguage(item.value);
                  }}
                >
                  Set as default
                </button>
              )}
              <button
                key={item.value}
                type="button"
                className="btn btn-sm btn-outline-dark"
                onClick={() => {
                  editButtonOnClick(item.value);
                }}
              >
                <i className="fas fa-pencil-alt" title="Edit Language" />
              </button>
            </span>
          </li>
        </React.Fragment>
      );
    }
    return null;
  });
};
