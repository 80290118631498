import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../../../../constants/routes';
import AppDownloadCard from './AppDownloadCard';
import Constants from '../../../../lib/constants';
import { appBuildStatus } from '../../../../lib/apiFunctions';

let pluginStatusCheck = false;

export default function DownloadWidget({ project }) {
  const [buildStatusResponse, setBuildStatusResponse] = useState({});
  const [buildStatus, setBuildStatus] = useState({});

  async function fetchBuildStatus() {
    try {
      const response = await appBuildStatus(project.id);
      setBuildStatusResponse(response);
    } catch (error) {
      // this.setState({});
    }
  }

  function startPluginStatusCheck() {
    fetchBuildStatus();
    pluginStatusCheck = setInterval(() => {
      fetchBuildStatus();
    }, 15000);
  }

  async function buildApp(platform) {
    setBuildStatus({
      ...buildStatus,
      [platform]: Constants.APP_STATUS_BUILDING
    });
    await axios({
      method: 'POST',
      url: `${API_URL}/v2/projects/${project.id}/build?platform=${platform}`,
      withCredentials: true
    })
    startPluginStatusCheck();
  }


  useEffect(() => {
    let buildComplete = true;
    const appStatus = {};
    project.apps.map(app => {
      let status;
      const preview = app.platformId == `${Constants.PLATFORM_IOS}`;
      if (buildStatusResponse.hasOwnProperty('status')) {
        status = preview
          ? buildStatusResponse.preview[app.id]
          : buildStatusResponse.status[app.id];
        if (typeof status !== 'string') {
          status = `${status}`;
        }
        buildComplete =
          buildComplete && status != Constants.APP_STATUS_BUILDING;
      } else {
        status = preview ? app.previewBuildStatus : app.appStatus;
        if (typeof status !== 'string') {
          status = `${status}`;
        }
        buildComplete = false;
        // Commented to perform single check with build status api
        // buildComplete =
        //   buildComplete && status !== Constants.APP_STATUS_BUILDING;
      }
      appStatus[app.platformId] = status;
    });

    setBuildStatus(appStatus);

    if (buildComplete && !pluginStatusCheck) {
      clearInterval(pluginStatusCheck);
      pluginStatusCheck = false;
    } else if (!pluginStatusCheck) {
      startPluginStatusCheck();
    }
    return () => {
      if (pluginStatusCheck) {
        clearInterval(pluginStatusCheck);
        pluginStatusCheck = false;
      }
    }
  }, [project.id, buildStatusResponse]);


  const appDownloadCard =
    project.apps &&
    project.apps.map(app => {
      const { token } = app;
      const preview = app.platformId == `${Constants.PLATFORM_IOS}`; // &&  app.iosCertUploaded === '0'
      const status = buildStatus[app.platformId];
      return (
        <AppDownloadCard
          key={`app-${app.id}`}
          project={project}
          app={app}
          buildApp={buildApp}
          preview={preview}
          appId={app.id}
          token={token}
          platform={
            app.platformId == `${Constants.PLATFORM_ANDROID}` ? 'android' : 'ios'
          }
          status={status}
          errorMessage={app.error_message}
        />
      );
    });

  return <div className="row justify-content-md-center">{appDownloadCard}</div>;
}
