import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, Button, ModalFooter } from 'reactstrap';
import Form from 'react-jsonschema-form';
import { getTranslationData } from '../../../../../../../lib/languages';
import PreLoading from '../../../../../../dashboard/layout/PreLoading';

export default ({
  modal,
  toggle,
  className,
  schema: defaultSchema = {},
  formData,
  onChange = () => {},
  onSubmit = () => {},
  currentLanguage,
  projectId,
  addToast = () => {}
}) => {
  const [isLoading, setLoading] = useState(false);
  const [updatedFormDataWithDefaultValue, setFormData] = useState({});
  const [schema, setSchema] = useState(defaultSchema);

  const fetchData = async ({ newFormData = {} }) => {
    setLoading(true);
    try {
      const response = await getTranslationData(projectId, currentLanguage);
      if (response.status && response.body) {
        setFormData({ ...response.body, ...newFormData });
        if (response.schema) {
          setSchema(response.schema);
        }
      } else {
        addToast('Default value loading failed', {
          appearance: 'error',
          autoDismiss: true
        });
      }
    } catch (error) {
      addToast('Default value loading failed', {
        appearance: 'error',
        autoDismiss: true
      });
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchData({ newFormData: formData });
  }, [currentLanguage]);

  return (
    <Modal isOpen={modal} toggle={toggle} className={className}>
      <ModalHeader toggle={toggle}>Translate To</ModalHeader>
      <ModalBody>
        {isLoading && (
          <div>
            <PreLoading single />
          </div>
        )}
        {!isLoading && (
          <React.Fragment>
            <div className="bg-white p-4 dashboard-box">
              <Form
                schema={schema}
                formData={updatedFormDataWithDefaultValue}
                onChange={props => {
                  setFormData(props.formData);
                  onChange(props.formData);
                }}
              >
                <button type="button" hidden="hidden" />
              </Form>
            </div>
          </React.Fragment>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={onSubmit} disabled={isLoading}>
          Update
        </Button>
{' '}
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};
