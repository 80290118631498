/**
 * Gutenberg Blocks
 *
 * All blocks related JavaScript files should be imported here.
 * You can create a new block folder in this dir and include code
 * for that block here as well.
 *
 * All blocks should be included here since this is the file that
 * Webpack is compiling as the input file.
 */
// import "./common.scss";

// import './block/banner';
import './style.css';
import registerBlock from './register';
import banner from './block/banner';
import grid from './block/grid';
import menu from './block/menu';
import imageScroller from './block/imageScroller';
import productScroller from './block/productScroller';
import prebuildProductScroller from './block/prebuildProductScroller';
import html from './block/html';
import slider from './block/slider';
import postlist from './block/postList';
import feed from './block/feed';
import profileCard from './block/profileCard';
import schedule from './block/schedule';
import singlePost from './block/singlePost';
import tab from './block/tab';
import shopifyPrebuildProductScroller from './block/shopifyPrebuildScroller';
import recentOrder from './block/recentOrder';
import preBuildProductGrid from './block/preBuildProductGrid';
import preBuildPostScroller from './block/preBuildPostScroller';
import preBuildPostGrid from './block/preBuildPostGrid';
import productGrid from './block/productGrid';

const availableBlocks = [
  banner,
  grid,
  menu,
  imageScroller,
  productScroller,
  prebuildProductScroller,
  html,
  slider,
  postlist,
  feed,
  profileCard,
  schedule,
  singlePost,
  tab,
  shopifyPrebuildProductScroller,
  recentOrder,
  preBuildProductGrid,
  preBuildPostScroller,
  preBuildPostGrid,
  productGrid
];

registerBlock(availableBlocks);
