/**
 * @param {array} array [ { value: "ar", label: "Arabic" }]
 * @param {Object} languages {ar:{translation:{Home:'My Home'}}}
 */
export default (array = [], languages = {}) => {
  const returnData = {};
  array.map(item => {
    returnData[item.value] = languages[item.value]
      ? languages[item.value]
      : { translation: {} };
  });
  return returnData;
};

/**
 *
 * @param {Object} languages {ar:{translation:{Home:'My Home'}}}
 * @param {Array} options [ { value: "ar", label: "Arabic" }]
 */
export const convertDefaultObjectValueToArray = (
  languages = {},
  options = []
) => {
  return Object.keys(languages).map(item =>
    options.find(opt => opt.value === item)
  );
};

/**
 *
 * @param {*} defaultLanguageList
 * @param {*} schemaOptions
 * @returns
 */
export const getOptionsFromLanguageMeta = (
  defaultLanguageList = [],
  schemaOptions = []
) => {
  return schemaOptions.filter(item => defaultLanguageList.includes(item.value));
};
