import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import Tabs from './Tabs';
import { getAppJson, updateApp } from '../../../lib/updateAppApi';
import * as ROUTES from '../../../constants/routes';
import PreLoading from '../../dashboard/layout/PreLoading';

export default ({ projectId, addToast = () => {}, isAdmin = false }) => {
  const [formData, setFormData] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [jsonData, setJsonData] = useState({});
  // to set the default value from the backend
  const [updateDefaultValues, setUpdateDefaultValue] = useState({});
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await getAppJson(projectId);
      if (response.status) {
        setJsonData(response.body.template);
        setFormData(response.body.formData);
        setUpdateDefaultValue(response.body.default);
      } else {
        addToast(response.debugInfo || 'Something went wrong', {
          appearance: 'error',
          autoDismiss: false
        });
      }
    } catch (err) {
      addToast(err.message || 'Something went wrong', {
        appearance: 'error',
        autoDismiss: false
      });
    }
    setLoading(false);
  };
  const updateData = async () => {
    try {
      setLoading(true);
      const updateResponse = await updateApp(projectId, { data: formData });
      if (updateResponse.status) {
        addToast('App update started', {
          appearance: 'success',
          autoDismiss: true
        });
        window.location.href = ROUTES.dashboardManageLink(projectId);
      } else {
        addToast(updateResponse.debugInfo || 'Something went wrong', {
          appearance: 'error',
          autoDismiss: false
        });
      }
    } catch (err) {
      addToast(err.message || 'Something went wrong', {
        appearance: 'error',
        autoDismiss: false
      });
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, []);
  const SaveButton = () => (
    <div className="text-right mt-5">
      <Button
        color="primary"
        onClick={() => {
          updateData(formData);
        }}
      >
        Update App
      </Button>
    </div>
  );
  return (
    <div>
      {isLoading && (
        <div>
          <PreLoading single />
        </div>
      )}
      {!isLoading && (
        <React.Fragment>
          <Tabs
            addToast={addToast}
            projectId={projectId}
            data={jsonData}
            formData={formData}
            onChangeMaster={changedData => {
              setFormData({ ...formData, ...changedData });
            }}
            footerItem={<SaveButton />}
            isAdmin={isAdmin}
            updateDefaultValues={updateDefaultValues}
          />
        </React.Fragment>
      )}
    </div>
  );
};
