import axios from 'axios';
import { Service } from 'axios-middleware';
import { getSessionToken } from "@shopify/app-bridge-utils";
const service = new Service(axios);

service.register({
    onRequest(config) {
        console.log('onRequest');
        if (window.shopifyApp) {
            console.log("Shopiy req");
            if (window.shopifySessionToken) {
                config.headers["Authorization"] = `Bearer ${window.shopifySessionToken}`;
                return config;
            }
            return getSessionToken(window.shopifyApp) // requires an App Bridge instance
                .then(token => {
                    // append your request headers with an authenticated token
                    config.headers["Authorization"] = `Bearer ${token}`;
                    return config;
                }).catch(err => {
                    console.log("Unable to get shopify token", err)
                });

        }
        console.log("Normal req");
        return config;
    },
    onSync(promise) {
        return promise;
    },
    onResponse(response) {
        return response;
    }
});

console.log('Ready to fetch.');