import React from 'react';
import { UPDATE_APP_REDIRECT } from '../../../lib/pushAutomationApi';

export default ({ afterPushConfigurationDone = () => {}, id, template }) => {
  const onSubmit = () => {
    afterPushConfigurationDone();
    window.location.href = UPDATE_APP_REDIRECT(template, id);
  };
  return (
    <div className="p-4 text-center">
      <h3 className="text-center">
        <i className="fas fa-check text-success mb-2 p-3 bg-light rounded-circle" />
      </h3>
      <h6 className="text-grey">Configured Push notification</h6>
      <h4>
        Please 
{' '}
<b>Update the app</b> before testing
      </h4>
      <p className="mb-4 text-muted">
        Update the app and test push notification on your updated app.
      </p>
      <button type="button" className="btn btn-primary" onClick={onSubmit}>
        <i className="fas fa-sync-alt mr-2" />
        Update App
      </button>
    </div>
  );
};
