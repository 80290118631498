import axios from 'axios';
import * as ROUTES from '../constants/routes';

const BASE_URL = `${ROUTES.API_URL}/v2/manage-project`;
const FILE_UPLOAD_URL = `${ROUTES.API_URL}/v2/file-upload`;

export const getAppJson = async projectId => {
  try {
    const url = `${BASE_URL}/get/${projectId}`;
    // const response = await fetch(url, {
    //   method: 'GET',
    //   credentials: 'include'
    // });
    // const respJson = await response.json();
    // return Promise.resolve(respJson);
    const response = await axios.get(url, {
      credentials: 'include',
      withCredentials: true
    });
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateApp = async (projectId, data) => {
  try {
    const url = `${BASE_URL}/update/${projectId}`;
    // const response = await fetch(url, {
    //   method: 'POST',
    //   body: JSON.stringify(data),
    //   credentials: 'include',
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   }
    // });
    // const respJson = await response.json();
    // return respJson;
    const response = await axios.post(url, data, {
      credentials: 'include',
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });
    return Promise.resolve(response.data);
  } catch (error) {
    console.log(error);
    return Promise.reject(error);
  }
};

export const uploadFile = async (projectId, data, tagName, extraParams) => {
  try {
    let url = `${FILE_UPLOAD_URL}/${projectId}?tagName=${tagName}`;
    if (extraParams) {
      url += `&${extraParams}`;
    }
    // const response = await fetch(url, {
    //   method: 'POST',
    //   body: data,
    //   credentials: 'include'
    // });
    // const respJson = await response.json();
    // return Promise.resolve(respJson);
    const response = await axios.post(url, data, {
      credentials: 'include',
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};
