import React, { useEffect } from 'react';
import 'react-sortable-tree/style.css';
import { Router, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Helmet } from 'react-helmet';
import createApp from '@shopify/app-bridge/development';
import qs from 'qs';
import { getSessionToken } from '@shopify/app-bridge-utils';

import './App.css';


// import ReactGA from 'react-ga';
import { logCurrentPage } from './utils/tracker';
import * as ROUTES from './constants/routes';

import AuthenticatedRoute from './components/RouterHOC/AuthenticatedRoute';
import Dashboard from './components/dashboard/Dashboard';
import ShopifyInstall from './components/ShopifyInstall';
import ManageApps from './components/manageApps/ManageApps';
import CustomerData from './components/dashboard/customerData';

import './store';
import './hooks';
import Plugins from './plugins';
import { api } from './store/actions';
// import Playground from './playground';

api.registerCoreActionTypes();
// ReactGA.initialize('UA-53887153-1');

const history = createBrowserHistory();

export default function App() {
  const queryParams = qs.parse(window.location.search, {
    ignoreQueryPrefix: true
  });
  if (queryParams.host) {
    console.log('Creating shopify instance');
    window.shopifyApp = createApp({
      apiKey:
        process.env.NODE_ENV === 'development'
          ? '45b133425d617958c21b795c3ed903fd'
          : 'd16097e6c4d94b41c3dde379ce2490fb',
      host: queryParams.host,
    });
    if (queryParams.shopifySessionToken) {
      window.shopifySessionToken = queryParams.shopifySessionToken;
    } else {
      getSessionToken(window.shopifyApp) // requires an App Bridge instance
        .then(token => {
          window.shopifySessionToken = token;
        }).catch(err => {
          console.log("Unable to get shopify token", err)
        });
    }
  } else {
    console.log(
      'Normal instance',
      queryParams,
      window.location.search,
      window.location.href
    );
  }

  useEffect(() => {
    logCurrentPage();
    history.listen(() => {
      logCurrentPage();
    });
  }, [history]);

  return (
    <Router history={history}>
      <Helmet>
        <title>Appmaker dashboard</title>
        <meta charSet="utf-8" />
        <meta name="description" content="A React.js application" />
      </Helmet>
      <Route path={ROUTES.SHOPIFY_INSTALL} component={ShopifyInstall} />
      <div className="container-fluid">
        <AuthenticatedRoute
          path={[ROUTES.DASHBOARD, ROUTES.DASHBOARD_MAIN]}
          exact
          component={Dashboard}
        />
      </div>
      <AuthenticatedRoute
        path={[
          '/',
          ROUTES.MANAGE,
          ROUTES.ACTIVE_APPS,
          ROUTES.DELETED_APPS,
          ROUTES.ACCOUNT_SETTINGS
        ]}
        exact
        component={ManageApps}
      />
      <AuthenticatedRoute
        path={[ROUTES.CUSTOMER_DATA]}
        exact
        component={CustomerData}
      />
      {/* <Route path={ROUTES.PLAYGROUND} component={Playground} /> */}
      <Plugins />
    </Router>
  );
}
