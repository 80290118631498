// NOTE  Inner block items should be placed along with the parent item to allow in template
export default () => {
  return [
    'appmaker/banner',
    'appmaker/grid',
    'appmaker/grid-item',
    'appmaker/menu',
    'appmaker/menu-item',
    'appmaker/slider',
    'appmaker/slider-item',
    'appmaker/html',
    'appmaker/imagescroller',
    'appmaker/imagescroller-item',
    'appmaker/shopify-prebuild-product-scroller'
  ];
};
