import React, { useState, useEffect } from 'react';
import { useToasts } from 'react-toast-notifications';
import { Row, Col } from 'reactstrap';
import AppCard from '../components/AppCard';
import SearchBar from './SearchBar';
import {
  getApiForProjects,
  deleteProjectInManageList
} from '../../../lib/apiFunctions';
import PreLoading from '../../dashboard/layout/PreLoading';

export default function ActiveApps({ active = true }) {
  const [isLoading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const { addToast } = useToasts();
  const loadData = async () => {
    setLoading(true);
    // getApiForProjects(active)
    //   .then(response => response.json())
    //   .then(response => {
    //     if (Array.isArray(response)) {
    //       setProjects(response);
    //     } else {
    //       setProjects([]);
    //     }
    //     setLoading(false);
    //   })
    //   .catch(() => {
    //     setProjects([]);
    //     setLoading(false);
    //   });
    try {
      const response = await getApiForProjects(active);
      setProjects(response);
    } catch (error) {
      setProjects([]);
    }
    setLoading(false);
  };

  async function deleteProject(id, restoreValue = false) {
    try {
      setLoading(true);
      const response = await deleteProjectInManageList(id, restoreValue);
      if (response.status === 'success') {
        loadData();
        addToast('Deleted Successfully', {
          appearance: 'success',
          autoDismiss: true
        });
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      addToast('Something Went Wrong', {
        appearance: 'error',
        autoDismiss: true
      });
    }
  }
  useEffect(() => {
    loadData();
  }, ['projects']);
  const projectsCards = projects.map(project => {
    return (
      <AppCard
        key={`project-${project.id}`}
        project={project}
        template={project.templateName}
        deleteProject={restoreValue => {
          deleteProject(project.id, restoreValue);
        }}
        active={active}
      />
    );
  });
  if (!isLoading) {
    return (
      <div>
        <SearchBar />
        <div className="container mt-4 d-flex justify-content-start flex-wrap">
          {projectsCards}
        </div>
      </div>
    );
  }

  return (
    <div>
      <SearchBar />
      <PreLoading appcard />
    </div>
  );
}
