import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import { select } from '@wordpress/data';
import { CustomizeIcon, InAppPage, MenuIcon } from '../Icons';
import * as ROUTES from '../../../../constants/routes';
import InAppPagesDropDown from '../../../InAppPagesDropDown';
import { trackEvent } from '../../../../utils/tracker';

const CustomizeApp = props => {
  const {
    subscriptionExpired,
    history,
    link,
    appId,
    onClick,
    toggleSideBar,
    isCustomApp,
    newPage,
    afterNewPage = false
  } = props;
  const project = select('appmaker/core').getCurrentProject();

  return (
    <li className="nav-item">
      {/* <NavLink
                to={`${ROUTES.CUSTOMIZE_APP}/${props.appId}`}
                activeClassName="active"
                className="vertical-nav-hover nav-link text-dark rounded-lg text-decoration-none"
              >
                <CustomizeIcon fill="#202020" />
                Customize App
              </NavLink> */}
      {!subscriptionExpired && (
        <a
          className="vertical-nav-hover nav-link text-dark rounded-lg text-decoration-none expanding-menu d-flex justify-content-between collapsed"
          data-toggle="collapse"
          href="#customizeAppExpand"
          role="button"
          aria-expanded="false"
          aria-controls="customizeAppExpand"
          onClick={() => {
            history.push(ROUTES.linkToInAppPage('home'));
            trackEvent('customize_app_clicked', { project_id: project.id });
          }}
        >
          <span>
            <CustomizeIcon fill="#202020" />
            Customize App
          </span>
          <i className="fas fa-chevron-down my-auto text-dark" />
        </a>
      )}
      {subscriptionExpired && (
        <Link
          onClick={onClick}
          to={`/apps/${appId}`}
          className="vertical-nav-hover nav-link text-dark rounded-lg text-decoration-none"
        >
          <CustomizeIcon fill="#202020" />
          Customize App
        </Link>
      )}
      <div className="collapse" id="customizeAppExpand">
        <div className="d-flex flex-column justify-content-end bg-white rounded-lg pb-1">
          <div className="nav-link text-dark rounded-lg text-decoration-none">
            <a
              className="vertical-nav-hover nav-link text-dark rounded-lg text-decoration-none expanding-menu d-flex justify-content-between collapsed"
              data-toggle="collapse"
              href="#collapseTwo"
              role="button"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              <span>
                <InAppPage />
                In-App Pages
              </span>
              <i className="fas fa-chevron-down my-auto text-dark" />
            </a>
            <div className="collapse" id="collapseTwo">
              <InAppPagesDropDown
                history={history}
                nameChanged={props.nameChanged}
                newPage={newPage}
                afterNewPage={data => {
                  if (afterNewPage !== false) {
                    afterNewPage(data);
                  }
                }}
              />
            </div>
          </div>
          <span className="border-bottom" />
          <NavLink
            onClick={toggleSideBar}
            activeClassName="active"
            to={
              isCustomApp
                ? ROUTES.linkToInAppPage('menu')
                : ROUTES.linkToInMenu()
            }
            className="vertical-nav-hover nav-link text-dark rounded-lg text-decoration-none mt-1 mx-2"
          >
            <MenuIcon />
            Menu
          </NavLink>
        </div>
      </div>
    </li>
  );
};
export default CustomizeApp;
