import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/performance';
import mixpanel from 'mixpanel-browser';
import { setPublicMeta, sendAppmakerEvent } from '../lib/apiFunctions';
import { mauticFormIdList } from './api-call/mauticApi';
import { sendToMautic } from './helper/mautic-helper';
import { sendToIntercom } from './helper/intercom-helper';
import { sendToFirebase } from './helper/firebase-helper';
// import { sendToRudder } from './helper/rudderAnalytics-helper';
// import moduleName from 'axios'
function appmakerEvent(event, params) {
  const { project_id } = params;
  try {
    console.log(event, params, 'app_event');
    if (project_id) {
      setPublicMeta(project_id, {
        [event]: true,
        [`${event}_data`]: params
      });
    }
  } catch (error) {
    console.log(error);
  }
  try {
    if (project_id) {
      sendAppmakerEvent(event, {
        ...params,
        project_id: parseInt(project_id)
      });
    } else {
      sendAppmakerEvent(event, params);
    }
  } catch (error) {
    console.log(error);
  }
}
const config = {
  apiKey: 'AIzaSyAaEw3Ww9i_cnASBp7GqY5ajFKnXBzvMsY',
  authDomain: 'appmaker-core.firebaseapp.com',
  databaseURL: 'https://appmaker-core.firebaseio.com',
  projectId: 'appmaker-core',
  storageBucket: 'appmaker-core.appspot.com',
  messagingSenderId: '746381077870',
  appId: '1:746381077870:web:f32630c1388af42116a57f',
  measurementId: 'G-EPKK53N9ET'
};
//
if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

mixpanel.init('b4f296a32d333c26a62b7c601e22f519');

const analytics = firebase.analytics();
const perf = firebase.performance();

export const setUser = (userId, params) => {
  analytics.setUserId(userId);
  analytics.setUserProperties(params);

  mixpanel.identify(userId);
  mixpanel.people.set({ $name: params.name, $email: params.email });
  // rudderanalytics.identify(userId, params);

  if (window.__insp) {
    window.__insp.push(['identify', { email: params.email }]);
  }
  if (window.profitwell) {
    window.profitwell('start', { user_email: params.email });
  }
  if (window.Intercom) {
    window.Intercom('boot', {
      email: params.email
    });
  }

  // console.log('Tracking', userId, params);
};

export const trackEvent = (
  event,
  params,
  { mautic = false, rudder = true, intercom = true, addFirebase = true } = {}
) => {
  try {
    // firebase analytics
    if (addFirebase) {
      mixpanel.track(event, params);
      sendToFirebase(event, params, analytics);
    }
    // rudder analytics
    // if (rudder) {
    //   sendToRudder(event, params);
    // }
    // Intercom
    if (intercom) {
      sendToIntercom(event, params);
    }
    // mautic
    if (mautic) {
      sendToMautic(event, params);
    }
    appmakerEvent(event, params);
  } catch (e) {
    console.error(e);
  }
};

export const logCurrentPage = () => {
  // console.log('Tracking page view', window.location.pathname);
  analytics.setCurrentScreen(window.location.pathname);
  analytics.logEvent('screen_view');
  mixpanel.track('page_view', {
    url: window.location.href,
    referer: window.document.referer,
    title: window.document.title,
    path: window.location.pathname
  });
  // sendAppmakerEvent('page_view', {
  //   url: window.location.href,
  //   referer: window.document.referer,
  //   title: window.document.title,
  //   path: window.location.pathname
  // });
  // rudderanalytics.page();
};

export const listMauticForm = mauticFormIdList;
