import React from 'react';

import Form from 'react-jsonschema-form';
import { getAllWidgets } from './helper/widgets';

export default ({
  schema,
  uiSchema,
  onChangeMaster = () => {},
  formData = {},
  onSubmit = () => {},
  addToast = () => {},
  projectId,
  translationTab,
  updateDefaultValues
}) => {
  const widgets = getAllWidgets({
    projectId,
    addToast,
    translationTab,
    updateDefaultValues
  });
  return (
    <div className="bg-white p-4 rounded-lg dashboard-box w-75">
      {schema && (
        <Form
          schema={schema}
          uiSchema={uiSchema}
          widgets={widgets}
          formData={formData}
          onChange={prop => {
            const { formData } = prop;
            // this.formOnChange(formData);
            onChangeMaster(formData);
          }}
          onSubmit={onSubmit}
        >
          <button type="button" hidden="hidden" />
        </Form>
      )}
    </div>
  );
};
