import React from 'react';
import Select from 'react-select';
import { find } from 'lodash';

export default props => {
  const { schema = {} } = props;
  const { options = [] } = schema;
  return (
    <Select
      defaultValue={find(options, { value: props.value })}
      options={options}
      className="basic-multi-select"
      classNamePrefix="select"
      isClearable
      isSearchable
      onChange={value => {
        props.onChange(value.value);
      }}
    />
  );
};
