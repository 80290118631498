// @flow strict

import * as React from 'react';
import '@wordpress/editor'; // This shouldn't be necessary
import { withSelect, dispatch } from '@wordpress/data';
// import { Redirect, useHistory } from 'react-router-dom';
import './style.css';
import {
  BlockEditorKeyboardShortcuts,
  BlockEditorProvider,
  BlockList,
  BlockInspector,
  WritingFlow,
  ObserveTyping
} from '@wordpress/block-editor';
import {
  Popover,
  SlotFillProvider,
  DropZoneProvider,
  Button,
  ToggleControl
} from '@wordpress/components';
// import Menu from '../dashboard/customizeApp/menu/MenuArea';

import '@wordpress/format-library';
import { withToastManager } from 'react-toast-notifications';
import { Input, FormGroup, Label } from 'reactstrap';
import Languages from './Languages';

import PhoneMenuBar from '../dashboard/customizeApp/components/buildComponents/PhoneMenuBar';
import { backupTheV1Data, clone } from '../../lib/apiFunctions';
import PreLoading from '../dashboard/layout/PreLoading';

/* eslint-disable no-restricted-syntax */
import '@wordpress/components/build-style/style.css';
import '@wordpress/block-editor/build-style/style.css';
import '@wordpress/block-library/build-style/style.css';
import '@wordpress/block-library/build-style/editor.css';
import '@wordpress/block-library/build-style/theme.css';
import '@wordpress/format-library/build-style/style.css';
/* eslint-enable no-restricted-syntax */
import * as ROUTES from '../../constants/routes';
// import gutenConverter from '../../../converter/gutenConverter';
import { verifyBlocks } from './verifyBlocks';
import { trackEvent } from '../../utils/tracker';
import SyncWithPlugin from '../dashboard/MessageScreens/SyncWithPlugin';
import SetHomePage from './setHome';

class InAppPageEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      blocks: [],
      styleView: 9,
      toggleJson: false,
      isLoading: false,
      title: '',
      isDeleting: false,
      pageId: '',
      language: 'default',
      cloneProject: false,
      cloneProjectId: '',
      cloneLoading: false,
      isPageTitleChanged: false
    };

    this.updateBlocks = this.updateBlocks.bind(this);
    this.cloneInAppPage = this.cloneInAppPage.bind(this);
  }

  componentDidMount() {
    // Component will unmount when changing the routes, So for fixing this not loading, componentDidMount is added and it will get the values from the props
    const { blocks, title, inAppPageId, language, project } = this.props;
    if (project.template === 'shopify' && parseInt(project.id) > 151416) {
      window.location.href = `https://v3.appmaker.xyz/${project.id}/dashboard/blockeditor/${inAppPageId}`;
      return;
    }
    if (blocks !== undefined && blocks.length !== 0) {
      this.setState({
        blocks,
        title: title === undefined ? '' : title,
        pageId: inAppPageId === undefined ? '' : inAppPageId,
        language: language === undefined ? 'default' : language
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // NOTE  To take the backup
    if (this.props.title !== undefined && this.props.project.id !== undefined) {
      if (this.props.version !== 'v1.1') {
        try {
          backupTheV1Data(this.props.project.id)
            .then(response => { })
            .catch(console.error);
        } catch (error) {
          console.log(error);
        }
      }
    }
    this.setState({
      title: nextProps.title,
      pageId: nextProps.inAppPageId,
      language: nextProps.language
    });

    if (
      this.state.blocks.length === 0 ||
      nextProps.inAppPageId != this.props.inAppPageId
    ) {
      this.updateBlocks(nextProps.blocks);
    }
  }

  // input for the api :{blocks:[] title:''}
  async saveInAppPages({
    blocks,
    pageId,
    isNew,
    template,
    projectId,
    isLanguageSwitching = false
  }) {
    const {
      toastManager,
      history,
      parentID,
      type,
      pageNameChange = false,
      user = {}
    } = this.props;
    const {
      title,
      language = 'default',
      isPageTitleChanged = false
    } = this.state;
    this.setState({ isLoading: true });
    const response = verifyBlocks(blocks);
    if (response.status === false) {
      toastManager.add('complete the action', {
        appearance: 'error',
        autoDismiss: true
      });
      return this.setState({ isLoading: false });
    }
    if (title === undefined || title.length === 0) {
      toastManager.add('Title cannot be empty', {
        appearance: 'error',
        autoDismiss: true
      });
      return this.setState({ isLoading: false });
    }
    if (isNew) {
      pageId = language === 'default' ? parentID : `${parentID}-${language}`;
    }
    const dataForUpdate = {
      id: pageId,
      language,
      parentID,
      version: 'v1.1',
      title,
      type,
      blocks,
      isPageTitleChanged
    };
    if (isNew === true) {
      dataForUpdate.is_new = isNew;
      dataForUpdate.blocks = [];
    }

    try {
      const responseSave = dispatch('appmaker/in-app-page').saveInAppPage(
        pageId,
        dataForUpdate
      );
      try {
        trackEvent(
          'in_app_page_saved',
          {
            page_id: pageId,
            title,
            blocks_count: blocks.length,
            template,
            project_id: projectId,
            email: user.email
          },
          {
            mautic: true
          }
        );
      } catch (error) { }
      return responseSave
        .then(async response => {
          if (dataForUpdate.is_new === true) {
            toastManager.add('Switched to Language Page', {
              appearance: 'success',
              autoDismiss: true
            });
            history.push(ROUTES.linkToInAppPage(`${pageId}`));
          } else {
            toastManager.add('Saved Successfully', {
              appearance: 'success',
              autoDismiss: true
            });
          }

          this.setState({ isLoading: false }, () => {
            // check the condition because the language page title should not change the default name in the sidebar.
            if (
              pageNameChange !== false &&
              language === 'default' &&
              !isLanguageSwitching
            ) {
              pageNameChange({ title, id: pageId });
            }
          });
        })
        .catch(error => {
          console.error('ERROR', error);
          if (isNew) {
            toastManager.add('Unable to switch page', {
              appearance: 'error',
              autoDismiss: true
            });
          } else {
            toastManager.add(error.message || 'Unable to save', {
              appearance: error.type || 'error',
              autoDismiss: true
            });
          }

          try {
            trackEvent('in_app_page_save_error', {
              page_id: pageId,
              template,
              project_id: projectId
            });
          } catch (error) { }

          this.setState({ isLoading: false });
        });
    } catch (error) {
      try {
        trackEvent('in_app_page_save_error', {
          page_id: pageId,
          template,
          project_id: projectId
        });
      } catch (error) { }
      toastManager.add('Unable to save', {
        appearance: 'error',
        autoDismiss: true
      });
      this.setState({ isLoading: false });
    }
  }

  // Switch language
  async switchLanguage({ isLanguageSwitching = false }) {
    const { toastManager = () => { }, history, parentID } = this.props;
    const { title, language = 'default' } = this.state;
    try {
      if (isLanguageSwitching) {
        const pageId =
          language === 'default' ? parentID : `${parentID}-${language}`;
        history.push(ROUTES.linkToInAppPage(`${pageId}`));
        this.setState({ isLoading: false });
        toastManager.add('Switched to Language Page', {
          appearance: 'success',
          autoDismiss: true
        });
        return true;
      }
      history.push(ROUTES.linkToInAppPage(parentID || 'home'));
      this.setState({ isLoading: false });
      toastManager.add('Unable to switch page', {
        appearance: 'error',
        autoDismiss: true
      });
      return false;
    } catch (error) {
      history.push(ROUTES.linkToInAppPage(parentID || 'home'));
      this.setState({ isLoading: false });
      toastManager.add('Unable to switch page', {
        appearance: 'error',
        autoDismiss: true
      });
      return false;
    }
  }

  async deleteInAppPage(appId, pageId, blocks, template) {
    const { toastManager } = this.props;
    this.setState({ isDeleting: true });
    if (pageId === 'home') {
      toastManager.add('Home page cannot be deleted', {
        appearance: 'error',
        autoDismiss: true
      });
      this.setState({ isDeleting: false });
    } else {
      try {
        // TODO  Notification
        const response = dispatch('appmaker/in-app-page').deleteInAppPages(
          pageId,
          blocks
        );
        response
          .then(resp => {
            if (resp.status === true) {
              this.setState({ isDeleting: false });
              try {
                trackEvent('in_app_page_deleted', {
                  page_id: pageId,
                  project_id: appId,
                  template
                });
              } catch (error) { }
              window.location.assign(ROUTES.linkToInAppPage('home'));
            }
          })
          .catch(error => {
            toastManager.add('Something went wrong', {
              appearance: 'error',
              autoDismiss: true
            });
            this.setState({ isDeleting: false });
            console.log(error);
          });
      } catch (error) {
        toastManager.add('Something went wrong', {
          appearance: 'error',
          autoDismiss: true
        });
        this.setState({ isDeleting: false });
        console.log('error', error);
      }
    }
  }

  updateBlocks(blocks, title) {
    this.setState({ blocks });
  }

  toggleJsonView() {
    const { toggleJson, styleView } = this.state;
    this.setState({ toggleJson: !toggleJson, styleView: !toggleJson ? 7 : 9 });
  }

  async cloneInAppPage({ cloneProjectId, isDuplicate = false }) {
    const { toastManager, inAppPageId, project, newPage = false } = this.props;
    // const { cloneProjectId } = this.state;
    this.setState({ cloneLoading: true });
    if (cloneProjectId === '') {
      toastManager.add('Enter valid project-id', {
        appearance: 'error',
        autoDismiss: true
      });
      return this.setState({ cloneLoading: false });
    }
    try {
      const response = await clone(
        `${project.id}/${inAppPageId}/${cloneProjectId}`
      );
      if (response.status === true) {
        toastManager.add(response.message, {
          appearance: 'success',
          autoDismiss: true
        });
        if (newPage !== false && isDuplicate === true)
          newPage({
            id: response.body.id,
            key: response.body.id,
            label: response.body.title
          });
      } else {
        toastManager.add(
          response.message === undefined
            ? 'Something went wrong'
            : response.message,
          {
            appearance: 'error',
            autoDismiss: true
          }
        );
      }
    } catch (error) {
      toastManager.add(error.message || 'Something went wrong', {
        appearance: 'error',
        autoDismiss: true
      });
    }
    return this.setState({ cloneLoading: false });
  }

  render() {
    // const blocks = ;
    // TO check the languages length
    const checkLanguageLength = languages => {
      if (languages !== undefined && languages.length > 0) return true;
      return false;
    };
    const {
      toggleJson,
      blocks,
      styleView,
      isLoading,
      title,
      pageId,
      language,
      cloneProject,
      cloneLoading,
      cloneProjectId
    } = this.state;
    const {
      user,
      languages,
      history,
      type,
      requiredSyncWithPlugin,
      projectTemplate,
      defaultLanguageCode
    } = this.props;
    const saveButtonLabel = requiredSyncWithPlugin
      ? 'Sync with plugin Required'
      : 'Save';
    return (
      <div className={type === 'TABBED' ? 'tabbed-page-container' : ''}>
        <SlotFillProvider>
          <DropZoneProvider>
            <BlockEditorProvider
              value={this.state.blocks}
              onInput={this.updateBlocks}
              onChange={this.updateBlocks}
            >
              <div className="row bg-light wrap">
                {/* {console.log(JSON.stringify(this.state.blocks))} */}
                {/* <div className="col-md-2 bg-white px-3 wrap overflow-auto">
                  <div className="drag-block-container">
                    <h6 className="sticky-top bg-white py-3 border-bottom">
                      {JSON.stringify(this.state.blocks)}
                    </h6>
                  </div>
                </div> */}
                {toggleJson && (
                  <div className="col-md-2 bg-white px-3 wrap overflow-auto">
                    <div className="drag-block-container">
                      <h6 className="sticky-top bg-white py-3 border-bottom">
                        JSON
                      </h6>
                      <div className="drag-block-list">
                        {JSON.stringify(blocks)}
                      </div>
                    </div>
                  </div>
                )}
                <div
                  className={`col-md-${styleView} bg-light app-build-area wrap overflow-auto position-relative`}
                >
                  <div className="row">
                    <div className="col-md-6 mx-auto">
                      <div className="app-view mt-3 mb-5">
                        <PhoneMenuBar title={title} />

                        <BlockEditorKeyboardShortcuts />
                        {/* menu-drop-area-container */}
                        <div className="phone-bg bg-white">
                          {title !== undefined && isLoading === false ? (
                            <WritingFlow>
                              <ObserveTyping>
                                <BlockList />
                              </ObserveTyping>
                            </WritingFlow>
                          ) : (
                            <React.Fragment>
                              <PreLoading app />
                              <PreLoading app />
                              <PreLoading app />
                            </React.Fragment>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <Route exact path={ROUTES.BUILD_VIEW} Component={AppBuildArea} />
                  <Route path={ROUTES.ADD_NEW_PAGE} Component={SelectPageType} /> */}
                <div className="col-md-3 bg-white wrap overflow-auto px-0">
                  <div className="px-3">
                    <h6 className="font-weight-bold mt-3">Settings</h6>
                    {user !== undefined &&
                      user.email.includes('@appmaker.xyz') && (
                        <React.Fragment>
                          <ToggleControl
                            label="View Json"
                            checked={toggleJson}
                            onChange={() => {
                              this.toggleJsonView();
                            }}
                          />
                          <ToggleControl
                            label="Clone inapp page to your project"
                            checked={cloneProject}
                            onChange={() => {
                              this.setState({ cloneProject: !cloneProject });
                            }}
                          />
                          {cloneProject &&
                            projectTemplate !== 'shopify' &&
                            projectTemplate !== 'customapp' && (
                              <div className="mb-3">
                                <FormGroup>
                                  <Label for="examplePassword">
                                    Destination project-id:
                                  </Label>
                                  <Input
                                    disabled={isLoading || cloneLoading}
                                    type="text"
                                    name="Project-Id"
                                    placeholder="Enter Project Id"
                                    value={cloneProjectId}
                                    onChange={event => {
                                      this.setState({
                                        cloneProjectId: event.target.value
                                      });
                                    }}
                                  />
                                </FormGroup>
                                <Button
                                  disabled={isLoading || cloneLoading}
                                  className="btn btn-warning btn-block "
                                  onClick={() => {
                                    this.cloneInAppPage({ cloneProjectId });
                                  }}
                                >
                                  {cloneLoading === true ? (
                                    <React.Fragment>
                                      <i className="fas fa-spinner mr-2 fa-spin" />
                                      {' '}
                                      Cloning.. Please wait
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      <i className="far fa-clone mr-2" /> Clone
                                    </React.Fragment>
                                  )}
                                </Button>
                              </div>
                            )}
                        </React.Fragment>
                      )}
                    {languages !== undefined &&
                      checkLanguageLength(languages) &&
                      language !== undefined && (
                        <FormGroup>
                          <Label for="examplePassword">Languages:</Label>
                          <Languages
                            defaultLanguageCode={defaultLanguageCode}
                            isDisabled={isLoading}
                            defaultValue={language}
                            languages={languages}
                            inAppPageId={this.props.inAppPageId}
                            onChange={async value => {
                              this.setState(
                                {
                                  language: value.value
                                },
                                async () => {
                                  await this.switchLanguage({
                                    blocks: this.state.blocks,
                                    pageId: this.props.inAppPageId,
                                    isNew: true,
                                    projectId: this.props.project.id,
                                    template: this.props.project.template,
                                    isLanguageSwitching: true
                                  });
                                }
                              );
                              // window.location.href = ROUTES.linkToInAppPage(
                              //   `${value.pageId}`
                              // );
                            }}
                          />
                        </FormGroup>
                      )}

                    {this.props.inAppPageId &&
                      !this.props.inAppPageId.includes('home') && (
                        <React.Fragment>
                          <FormGroup>
                            <Label for="examplePassword">Page Title:</Label>
                            <Input
                              disabled={isLoading}
                              type="text"
                              name="In-App Page Title"
                              value={title}
                              onChange={event => {
                                this.setState({
                                  title: event.target.value,
                                  isPageTitleChanged: true
                                });
                              }}
                              required
                            />
                          </FormGroup>
                        </React.Fragment>
                      )}
                    {requiredSyncWithPlugin && (
                      <React.Fragment>
                        <SyncWithPlugin />
                      </React.Fragment>
                    )}
                    <Button
                      disabled={isLoading}
                      className="btn btn-success btn-block "
                      onClick={() => {
                        this.saveInAppPages({
                          blocks: this.state.blocks,
                          pageId,
                          projectId: this.props.project.id,
                          template: this.props.project.template
                        });

                        /*
                                  const result = await this.saveInnAppPages(
                                  this.props.project.id,
                                  this.state.blocks,
                                  this.props.inAppPageId,
                                  this.props.project.template,
                                  this.setState
                                );
                                */
                      }}
                    >
                      {' '}
                      <i className="fas fa-save mr-2" />
                      {isLoading === true ? 'Saving...' : 'Save'}
                    </Button>
                  </div>
                  <div className="config-components" />
                  <BlockInspector />
                  <div className="delete-button py-3 mb-1 border-top px-3 d-flex flex-wrap justify-content-center">
                    {projectTemplate !== 'shopify' &&
                      projectTemplate !== 'customapp' && (
                        <React.Fragment>
                          <SetHomePage
                            projectId={this.props.project.id}
                            pageKey={this.props.inAppPageId}
                            disabled={isLoading}
                            addToast={this.props.toastManager.add}
                            afterComplete={switchPageKey => {
                              window.location.assign(
                                ROUTES.linkToInAppPage(switchPageKey || 'home')
                              );
                            }}
                          />
                          <Button
                            className="btn btn-sm btn-outline-dark m-1"
                            disabled={cloneLoading}
                            onClick={() => {
                              this.cloneInAppPage({
                                cloneProjectId: this.props.project.id,
                                isDuplicate: true
                              });
                            }}
                          >
                            {cloneLoading === true ? (
                              <React.Fragment>
                                <i className="fas fa-spinner mr-2 fa-spin" />
                                {' '}
                                Duplicating...
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                <i className="far fa-clone mr-2" />
                                {' '}
                                Duplicate
                                Page
                              </React.Fragment>
                            )}
                          </Button>
                        </React.Fragment>
                      )}

                    {this.props.inAppPageId !== 'menu' && (
                      <Button
                        className="btn btn-sm btn-outline-danger m-1"
                        disabled={this.state.isDeleting}
                        onClick={() => {
                          const alertResponse = window.confirm(
                            'Are you sure ?'
                          );
                          if (alertResponse) {
                            this.deleteInAppPage(
                              this.props.project.id,
                              this.props.inAppPageId,
                              this.state.blocks,
                              this.props.project.template
                            );
                          }
                        }}
                      >
                        <i className="fas fa-trash mr-2" />
                        {' '}
                        {this.state.isDeleting ? 'Deleting ...' : 'Delete Page'}
                      </Button>
                    )}
                  </div>
                </div>
              </div>
              <Popover.Slot />
            </BlockEditorProvider>
          </DropZoneProvider>
        </SlotFillProvider>
      </div>
    );
  }
}

// export default InAppPageEditor;
export default withSelect((select, props) => {
  const project = select('appmaker/core').getCurrentProject();
  const user = select('appmaker/core').getUser();
  let blocks = [];
  let title;
  let version;
  let language;
  let parentID;
  let type;
  let requiredSyncWithPlugin = false;
  const inAppPageResponse = select('appmaker/in-app-page').getInAppPage(
    props.inAppPageId || 'home'
  );

  if (inAppPageResponse !== undefined) {
    blocks = inAppPageResponse.blocks;
    version = inAppPageResponse.version;
    title = inAppPageResponse.title;
    language = inAppPageResponse.language;
    parentID = inAppPageResponse.parentID || props.inAppPageId;
    type = inAppPageResponse.type;
    requiredSyncWithPlugin = inAppPageResponse.requiredSyncWithPlugin;
  }
  const projectTemplate = project.template || '';

  return {
    blocks,
    project,
    title,
    version,
    user,
    languages: project.languages,
    language,
    parentID,
    type,
    requiredSyncWithPlugin,
    projectTemplate,
    defaultLanguageCode: project.defaultLanguageCode
  };
})(withToastManager(InAppPageEditor));
