import React, { useState } from 'react';
import Select from 'react-select';
import TranslationPage from './helper-components/TranslationModal';
import OnChangeValueToObject, {
  convertDefaultObjectValueToArray,
  getOptionsFromLanguageMeta
} from './helper/language-array';
import EditButton from './helper-components/GenerateEditButton';

export default props => {
  const {
    schema = {},
    value = {},
    translationTab,
    onChange = () => {},
    projectId,
    addToast = { addToast },
    availableLanguageList
  } = props;
  const { schema: translationSchema } = translationTab;
  // const { options = [] } = schema;
  const options = getOptionsFromLanguageMeta(
    availableLanguageList,
    schema.options
  );
  // for lanugae options
  // const [options, setOption] = schema && schema.options ? schema.options : [];
  // for translation tab
  const [currentLanguage, setCurrentLanguage] = useState(false);
  // const [currentLanguage, setCurrentLanguage] = useState(false);
  // For storing translation form data
  const [newTranslationFormData, setFormData] = useState(
    currentLanguage && value && value.languages
      ? value.languages[currentLanguage]
      : undefined
  );
  const [modal, setModal] = useState(false);
  // modal toggle
  const toggle = () => setModal(!modal);
  // Edit languages button click event
  const editButtonOnClick = data => {
    setCurrentLanguage(data);
    // Append data to show
    const formValue =
      value && value.languages ? value.languages[data].translation : undefined;
    // to set default value
    // if (formValue && Object.keys(formValue).length === 0 && defaultFormData) {
    //   formValue = defaultFormData[data]
    //     ? defaultFormData[data].translation
    //     : undefined;
    // }
    setFormData(formValue);
    toggle();
  };
  // Language select box value onChange
  const onChangeLanguage = newValue => {
    onChange({
      ...value,
      languages: {
        ...OnChangeValueToObject(newValue, value.languages)
      },
      defaultLanguageCode: value.defaultLanguageCode
        ? value.defaultLanguageCode
        : newValue[0].value
    });
  };
  const setDefaultLanguage = defaultLanguage => {
    onChange({
      ...value,
      defaultLanguageCode: defaultLanguage
    });
  };
  // Translation tab submit
  const translationSubmit = () => {
    onChange({
      ...value,
      languages: {
        ...value.languages,
        [currentLanguage]: {
          translation: newTranslationFormData
        }
      }
    });
    toggle();
  };
  return (
    <React.Fragment>
      <Select
        defaultValue={
          value && value.languages
            ? convertDefaultObjectValueToArray(value.languages, options)
            : undefined
        }
        isMulti
        options={options}
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={onChangeLanguage}
      />
      {value && (value.defaultLanguageCode || value.languages) && (
        <div className="pt-4 pb-2">
          Default Language selected:
          <span className="pl-2">
            {value.defaultLanguageCode || Object.keys(value.languages)[0]}
          </span>
        </div>
      )}
      {/* {props && value && generateEditButton()} */}
      {props && value && (
        <div className="my-4">
          <h4 className="mb-3">
            <i className="fas fa-language mr-2 text-primary" />
            Languages
          </h4>
          <ul className="list-group">
            {/* {generateEditButton(
              value.defaultLanguageCode || Object.keys(value.languages)[0]
            )} */}
            <EditButton
              options={options}
              _defaultLanguageCode={
                value.defaultLanguageCode || Object.keys(value.languages)[0]
              }
              value={value}
              setDefaultLanguage={setDefaultLanguage}
              editButtonOnClick={editButtonOnClick}
            />
          </ul>
        </div>
      )}
      {value && value.languages && currentLanguage && (
        <React.Fragment>
          <TranslationPage
            modal={modal}
            toggle={toggle}
            schema={translationSchema}
            formData={newTranslationFormData}
            onChange={data => {
              setFormData(data);
            }}
            projectId={projectId}
            onSubmit={translationSubmit}
            currentLanguage={currentLanguage}
            addToast={addToast}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
