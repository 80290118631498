import React from 'react';
import { RichText } from '@wordpress/block-editor';
import { extractTextFromHTML } from '../../helper';

export default props => {
  const { attributes, setAttributes, className } = props;
  const { title } = attributes;
  return (
    <div className="block-title-part d-flex p-2 justify-content-between">
      <div className="block-title my-auto">
        <RichText
          allowedFormats={[]}
          value={title}
          withoutInteractiveFormatting
          onChange={text => {
            setAttributes({ title: extractTextFromHTML(text) });
          }}
          placeholder="Product Scroller title"
        />
      </div>
      {/* <div className="view-more-button">
        <div className="btn btn-light btn-sm">
          <RichText
            allowedFormats={[]}
            value={ctaText}
            withoutInteractiveFormatting
            onChange={ctaTexts => {
              setAttributes({ ctaText: extractTextFromHTML(ctaTexts) });
            }}
            placeholder="View More Button"
          />
        </div>
      </div> */}
    </div>
  );
};
