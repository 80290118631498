import React, { useEffect, useState } from 'react';
import AsyncCreateSelect from 'react-select/lib/AsyncCreatable';
import AsyncSelect from 'react-select/lib/Async';
import { newApiProxy } from '../../../lib/apiFunctions';

export default props => {
  const {
    projectId,
    dataSource = {},
    value,
    onChange = () => {},
    isMulti = false,
    isCreatable = false
  } = props;

  const promiseOptions = input => {
    return new Promise(resolve => {
      if (
        dataSource !== undefined &&
        dataSource.link !== undefined &&
        dataSource.store !== 'core'
      ) {
        let searchQuery = dataSource.link;
        if (dataSource.depend === true) {
          searchQuery =
            input !== undefined && input.length !== 0
              ? `${searchQuery}&search=${input}`
              : searchQuery;
        } else {
          searchQuery =
            input !== undefined && input.length !== 0
              ? `${searchQuery}?search=${input}`
              : searchQuery;
        }

        newApiProxy(projectId, searchQuery)
          .then(value => {
            const result = value.body;
            if (dataSource.showAll && result !== undefined) {
              result.push({ id: '', label: 'All' });
            }
            return resolve(result);
          })
          .catch(error => {
            // to avoid error
            return resolve([]);
          });
      }
    });
  };
  const onChangeCreatable = (onChangeValue, options) => {
    const { action = 'select-option' } = options || {};
    if (action === 'select-option') {
      onChange(onChangeValue);
    } else {
      const out = onChangeValue.map(item => {
        return {
          id: item.id || item.value,
          label: item.label
        };
      });
      onChange(out);
    }
  };
  if (isCreatable) {
    return (
      <div>
        <AsyncCreateSelect
          getOptionLabel={option => option.label}
          getOptionValue={option => option.id}
          name="multi-select"
          loadOptions={promiseOptions}
          onChange={onChangeCreatable}
          isMulti={isMulti}
          defaultValue={value}
          cacheOptions
          defaultOptions
        />
      </div>
    );
  }

  return (
    <div>
      <AsyncSelect
        getOptionLabel={option => option.label}
        getOptionValue={option => option.id}
        name="multi-select"
        loadOptions={promiseOptions}
        onChange={onChangeValue => {
          onChange(onChangeValue);
        }}
        isMulti={isMulti}
        defaultValue={value}
        cacheOptions
        defaultOptions
      />
    </div>
  );
};
