export const mapActionValueToSubAction = action => {
  switch (action) {
    case 'OPEN_IN_WEBVIEW': {
      return 'OPEN_IN_WEBVIEW_url';
    }
    case 'OPEN_URL': {
      return 'OPEN_URL_url';
    }
    case 'OPEN_INAPP_PAGE': {
      return 'OPEN_INAPP_PAGE_id';
    }
    case 'OPEN_PRODUCT_BY_CATEGORY': {
      return 'OPEN_PRODUCT_BY_CATEGORY_PRODUCT_ID';
    }
    case 'OPEN_POST_PAGE': {
      return 'OPEN_POST_PAGE_PRODUCT_ID';
    }
    case 'LIST_PRODUCT': {
      return 'OPEN_CATEGORY_PRODUCT_ID';
    }
    case 'OPEN_PRODUCT': {
      return 'OPEN_PRODUCT_PRODUCT_ID';
    }
    case 'OPEN_PRODUCT_TAG': {
      return 'OPEN_PRODUCT_TAG_PRODUCT_ID';
    }
    case 'CURRENCY_SWITCHER': {
      return true;
    }
    case 'OPEN_ZOPIM_CHAT': {
      return true;
    }
    case 'NO_ACTION': {
      return true;
    }
    case 'OPEN_SHOP_PAGE': {
      return 'OPEN_SHOP_PAGE_PRODUCT_ID';
    }
    case 'LANGUAGE_SWITCHER': {
      return true;
    }
    case 'OPEN_ATTRIBUTE': {
      return 'OPEN_ATTRIBUTE_ATTRIBUTE_ID';
    }
    case 'OPEN_VENDORS': {
      return true;
    }
    case 'OPEN_CATEGORY': {
      return 'OPEN_CATEGORY_PRODUCT_ID';
    }
    case 'OPEN_BY_BRAND': {
      return 'OPEN_BY_BRAND_CATEGORY_ID';
    }
    case 'OPEN_BRANDS': {
      return true;
    }
    case 'OPEN_PRODUCT_BRAND': {
      return 'OPEN_PRODUCT_BRAND_BRAND_ID';
    }
    case 'OPEN_AUTHENTICATED_WEBVIEW': {
      return 'OPEN_AUTHENTICATED_WEBVIEW_url';
    }
    case 'LIST_NEW_PRODUCTS': {
      return 'LIST_NEW_PRODUCTS_PRODUCT_ID';
    }
    case 'OPEN_PRE_BUILD': {
      return 'OPEN_PRE_BUILD_OPTION_ID';
    }
    case 'OPEN_COLLECTION': {
      return 'OPEN_COLLECTION_ID';
    }
    case 'OPEN_PRODUCT_LIST': {
      return true;
    }
    case 'OPEN_BY_TAG': {
      return 'OPEN_BY_TAG_ID';
    }
    case 'OPEN_BLOG_LIST': {
      return 'OPEN_BLOG_LIST_ID';
    }
    case 'OPEN_PAGE': {
      return 'OPEN_PAGE_ID';
    }
    case 'CATEGORY_WITH_PRODUCT': {
      return 'CATEGORY_WITH_PRODUCT_CATEGORY_ID';
    }
    default: {
      return undefined;
    }
  }
};
const subActions = [
  'OPEN_IN_WEBVIEW_url',
  'OPEN_URL_url',
  'OPEN_PRODUCT_BY_CATEGORY_PRODUCT_ID',
  'OPEN_POST_PAGE_id',
  'OPEN_INAPP_PAGE_id',
  'OPEN_CATEGORY_PRODUCT_ID',
  'OPEN_PRODUCT_PRODUCT_ID',
  'OPEN_SHOP_PAGE_PRODUCT_ID',
  'OPEN_ATTRIBUTE_ATTRIBUTE_ID',
  'OPEN_COLLECTION_ID'
];
export const verifyAction = data => {
  const { action } = data;
  if (action === undefined) {
    return undefined;
  }
  const subAction = mapActionValueToSubAction(action);
  if (subAction === true) {
    return true;
  }
  if (subAction === undefined) {
    return undefined;
  }
  if (data[`${subAction}`] === undefined) {
    return undefined;
  }
  return true;
};
