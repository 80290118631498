// NOTE  Inner block items should be placed along with the parent item to allow in template
export default () => {
  return [
    'appmaker/banner',
    'appmaker/grid',
    'appmaker/grid-item',
    'appmaker/menu',
    'appmaker/menu-item',
    'appmaker/slider',
    'appmaker/slider-item',
    'appmaker/html',
    'appmaker/imagescroller',
    'appmaker/imagescroller-item',
    'appmaker/prebuildproductscroller',
    'appmaker/productscroller',
    'appmaker/productscroller-item',
    'appmaker/recent-orders',
    'appmaker/pre-build-product-grid',
    'appmaker/pre-build-post-scroller',
    'appmaker/pre-build-post-grid',
    'appmaker/productscroller-grid',
    'appmaker/productscroller-grid-item'
  ];
};
