import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle
} from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner
} from 'reactstrap';
import { withToastManager } from 'react-toast-notifications';
import { appBuildStatus, downloadApp } from '../../../../../lib/apiFunctions';
import constants from '../../../../../lib/constants';
import { loadingImg } from '../../../../../images';
import {
  buildIpaFile,
  ipaUploadStatus,
  uploadIpaFile
} from '../../../../../lib/iosApi';

let statusCheck = false;

const IPAUpload = forwardRef(
  (
    {
      toastManager,
      appId,
      projectId,
      showAuthKeyUpload,
      buttonLabel,
      className,
      showButton
    },
    ref
  ) => {
    const [modal, setModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [status, setStatus] = useState(1);
    const [alreadyUploaded, setAlreadyUploaded] = useState(false);
    const [step, setStep] = useState('app-build'); // ipa-upload
    useImperativeHandle(ref, () => ({
      toggle() {
        if (!modal) {
          buildIpa();
        }
        setModal(!modal);
      }
    }));

    function startBuildStatusCheck(statusType) {
      async function statusChecker() {
        let responseStatus;

        if (statusType === 'app-build') {
          const response = await appBuildStatus(projectId);
          responseStatus = response.status[appId];
          setStatus(response.status[appId]);
          setLoading(false);
        } else {
          const response = await ipaUploadStatus(projectId, appId);
          responseStatus = response.status;
          setStatus(responseStatus);
        }
        if (responseStatus != constants.APP_STATUS_BUILDING) {
          clearInterval(statusCheck);
        }
      }
      statusCheck = setInterval(statusChecker, 8000);
      statusChecker();
    }

    async function uploadIpa() {
      setLoading(true);
      const response = await uploadIpaFile(projectId, appId);
      if (response.already_uploaded === true) {
        setStep('ipa-upload');
        setStatus(2);
        setAlreadyUploaded(true);
        setLoading(false);
      } else if (
        response.certificate_create_status != constants.APP_STATUS_SUCCESS
      ) {
        toggle();
        showAuthKeyUpload();
      } else {
        setStep('ipa-upload');
        setStatus(1);
        startBuildStatusCheck('ipa-upload');
        setLoading(false);
      }
    }

    async function buildIpa() {
      setLoading(true);
      setStep('app-build');
      await buildIpaFile(appId, projectId);
      startBuildStatusCheck('app-build');
    }

    function toggle() {
      if (modal && statusCheck !== false) {
        clearInterval(statusCheck);
      } else if (!modal) {
        buildIpa();
      }
      setModal(!modal);
    }

    return (
      <React.Fragment>
        {showButton && (
          <Button size="sm" color="light" className="mx-1" onClick={toggle}>
            {buttonLabel}
          </Button>
        )}
        <Modal isOpen={modal} toggle={toggle} className={className} scrollable>
          <ModalHeader className="border-bottom-0 shadow-sm" toggle={toggle}>
            <h5 className="font-weight-bold ml-3">
              Upload ipa into iTunes connect
            </h5>
          </ModalHeader>
          <ModalBody className="p-3 position-relative">
            <div className="py-2 h-100">
              {loading && <BuildingStates close={toggle} loading />}

              {/* Building */}
              {!loading &&
                status == constants.APP_STATUS_BUILDING &&
                step === 'app-build' && (
                  <BuildingStates close={toggle} building />
                )}

              {/* uploading */}
              {!loading &&
                status == constants.APP_STATUS_BUILDING &&
                step === 'ipa-upload' && (
                  <BuildingStates close={toggle} uploading />
                )}

              {/* Failed */}
              {!loading && status == constants.APP_STATUS_FAILED && (
                <BuildingStates close={toggle} failed />
              )}

              {/* success */}
              {!loading &&
                status == constants.APP_STATUS_SUCCESS &&
                step === 'app-build' && (
                  <div className="d-flex flex-column justify-content-center align-items-center h-100">
                    <h4>
                      <i className="fas fa-check-circle text-success mr-2" />
                      IPA Build Success
                    </h4>
                    {/* <small className="mb-3">Some helper text here</small> */}
                    <span>
                      <a
                        href={downloadApp(appId, false, '')}
                        className="btn btn-success"
                      >
                        <i className="fa fa-download mr-2" />
                        Download IPA File
                      </a>
                      <Button
                        color="dark"
                        className="mx-1"
                        onClick={() => {
                          uploadIpa();
                        }}
                      >
                        Upload IPA to iTunes Connect
                      </Button>
                    </span>
                  </div>
                )}

              {!loading &&
                status == constants.APP_STATUS_SUCCESS &&
                step === 'ipa-upload' && (
                  <BuildingStates
                    close={toggle}
                    uploadSuccess
                    alreadyUploaded={alreadyUploaded}
                  />
                )}
            </div>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
);

const BuildingStates = ({
  close,
  loading,
  building,
  uploading,
  failed,
  uploadSuccess,
  alreadyUploaded
}) => {
  if (loading)
    return (
      <div className="d-flex flex-column justify-content-center align-items-center h-100">
        <Spinner color="dark" />
      </div>
    );
  if (building)
    return (
      <div className="d-flex flex-column justify-content-center align-items-center h-100">
        <img
          src={loadingImg.blackLoading}
          alt="Building IPA"
          width="150px"
          className="mb-3"
        />
        <h5>Building IPA File</h5>
        <small>(Estimated time: ~20min)</small>
      </div>
    );
  if (uploading)
    return (
      <div className="d-flex flex-column justify-content-center align-items-center h-100">
        <img
          src={loadingImg.uploading}
          alt="Building IPA"
          width="150px"
          className="mb-3"
        />
        <h5>Uploading IPA File</h5>
        <small>(Estimated time: 3min)</small>
      </div>
    );
  if (alreadyUploaded)
    return (
      <div className="d-flex flex-column justify-content-center align-items-center h-100">
        <h4>
          <i className="fas fa-check-circle text-success mr-2" />
          Current IPA is already uploaded
        </h4>
        <a
          target="_blank"
          href="https://appmaker.xyz/docs/version-release-of-ios-app/"
        >
          <small className="mb-3">Documentation to release uploaded ipa</small>
        </a>
        <Button
          onClick={() => {
            close();
          }}
          color="primary mt-2"
        >
          Close
        </Button>
      </div>
    );
  if (uploadSuccess)
    return (
      <div className="d-flex flex-column justify-content-center align-items-center h-100">
        <h4>
          <i className="fas fa-check-circle text-success mr-2" />
          IPA Uploaded Successfully
        </h4>
        <a
          target="_blank"
          href="https://appmaker.xyz/docs/version-release-of-ios-app/"
        >
          <small className="mb-3">Documentation to release uploaded ipa</small>
        </a>
        <Button
          onClick={() => {
            close();
          }}
          color="primary mt-2"
        >
          Close
        </Button>
      </div>
    );
  if (failed)
    return (
      <div className="d-flex flex-column justify-content-center align-items-center h-100">
        <h4>
          <i className="fas fa-times-circle text-warning mr-2" />
          Failed
        </h4>
        <small className="mb-3">Please contact support for assistance</small>
        <Button
          color="primary mt-2"
          onClick={() => {
            window.Intercom('showNewMessage');
          }}
        >
          Contact Support
        </Button>
      </div>
    );
};

export default withToastManager(IPAUpload);
