import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect
} from 'react';
import { Redirect } from '@shopify/app-bridge/actions';
import axios from 'axios';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Row,
  Col,
  CustomInput,
  Alert
} from 'reactstrap';
import getCountry from '../../../../lib/getCountry';

import './style.css';
import PlanListing from './components/PlanListing';
import * as paymentPlans from './plans';
import { API_URL } from '../../../../constants/routes';
import { trackEvent } from '../../../../utils/tracker';
import CopyField from '../../connectStore/copyField';

const ChoosePlan = forwardRef(
  (
    {
      showPaymentButton = true,
      paymentText = 'Make Payment',
      projectId,
      paymentButtonClass = 'btn btn-light mt-2',
      template = 'woocommerce',
      email = '',
      planId
    },
    ref
  ) => {
    const [modal, setModal] = useState(false);
    const [country, setCountry] = useState(false);
    const [yearly, setYearly] = useState(false);
    const [packageId, setPackageId] = useState('growth');
    const [skipToggleButton, setSkipToggleButton] = useState(false);
    const [loading, setLoading] = useState(false);
    const plans = paymentPlans[template];
    const plansCount = Object.keys(plans).length;

    function getPlanRegion() {
      const categoryOneCountries = [
        'AU',
        'BE',
        'CA',
        'CL',
        'FR',
        'DE',
        'IS',
        'IT',
        'KW',
        'MY',
        'NL',
        'PT',
        'QA',
        'RO',
        'SA',
        'AE',
        'GB',
        'US'
      ];
      if (country === 'IN') {
        return 'india';
      }
      if (categoryOneCountries.indexOf(country) > -1) {
        return 'region_1';
      }
      return 'default';
    }

    function getRegionalPlan(plan) {
      const { skipMonthly = false, pricing = false } = plan;
      const planRegion = getPlanRegion();
      const planIntervel =
        !skipMonthly && yearly === false ? 'monthly' : 'yearly';
      if (
        pricing &&
        Object.hasOwnProperty.call(pricing, planIntervel) &&
        Object.hasOwnProperty.call(pricing[planIntervel], planRegion)
      ) {
        return pricing[planIntervel][planRegion];
      }
      if (
        pricing &&
        Object.hasOwnProperty.call(pricing, planIntervel) &&
        !Object.hasOwnProperty.call(pricing[planIntervel], planRegion) &&
        Object.hasOwnProperty.call(pricing[planIntervel], 'default')
      ) {
        return pricing[planIntervel].default;
      }
      return false;
    }

    useEffect(() => {
      async function setCountryCode() {
        const countryCode = await getCountry();
        setCountry(countryCode);
      }
      if (!country) {
        setCountryCode();
      }
      // TODO: Fix later (temporary fix for shopify review)
      if (planId === 95 || planId === 96) {
        setPackageId('scale');
      } else if (planId === 94 || planId === 93) {
        setPackageId('growth');
      } else if (planId === 92 || planId === 91) {
        setPackageId('basic');
      }
    }, planId);

    useImperativeHandle(ref, () => ({
      toggle() {
        setModal(!modal);
      }
    }));

    const toggle = () => {
      // console.log('Toggle clicked');
      setYearly(false);
      if (!modal) {
        try {
          trackEvent('checkout_step_plan_choose', { project_id: projectId });
        } catch (error) { }
      }
      setModal(!modal);
    };
    const billToggle = () => {
      setYearly(!yearly);
      trackEvent('checkout_year_toggle', {
        project_id: projectId,
        yearly
      });
    };

    function proceed() {
      if (template === 'woocommerce') {
        window.location.href = `https://v3.appmaker.xyz/apps/${projectId}/subscription`;
        return false;
      }
      setLoading(true);
      try {
        trackEvent(
          'payment_start',
          { project_id: projectId, email },
          {
            mautic: true
          }
        );
      } catch (error) { }

      if (template === 'shopify') {
        const shopifyPlanId = yearly
          ? plans[packageId].shopifyId.yearly
          : plans[packageId].shopifyId.monthly;

        axios
          .get(
            `${API_URL}/v2/payment/${projectId}/shopify?planId=${shopifyPlanId.usd}`,
            {
              withCredentials: true
            }
          )
          .then(response => {
            if (!window.shopifyApp) {
              console.log('Not inside shopify redrecting via href');
              window.location.href = response.data.confirmationUrl;
            } else {
              console.log('Shopify - redrecting via Shopify redirect');
              const redirect = Redirect.create(window.shopifyApp);
              redirect.dispatch(
                Redirect.Action.REMOTE,
                response.data.confirmationUrl
              );
            }
            setLoading(true);
          })
          .catch(error => {
            console.error(error);
            setLoading(false);
          });
      } else {
        setModal(false);
        let chargebeePlanId;
        const regionalPlan = getRegionalPlan(plans[packageId]);
        if (regionalPlan) {
          chargebeePlanId = regionalPlan.chargebeePlanId;
        } else {
          chargebeePlanId = yearly
            ? plans[packageId].chargebeePlanId.yearly
            : plans[packageId].chargebeePlanId.monthly;
        }

        const cbInstance = window.Chargebee.init({
          site:
            process.env.NODE_ENV === 'production' ? 'appmaker' : 'appmaker-test'
        });
        setLoading(false);
        cbInstance.openCheckout({
          hostedPage: () => {
            return fetch(
              `${API_URL}/v2/payment/${projectId}/chargebee-hosted-page`,
              {
                credentials: 'include',
                method: 'POST',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  plan: chargebeePlanId
                })
              }
            )
              .then(response => {
                return response.json();
              })
              .then(response => {
                if (response.status && response.hosted_page) {
                  return response.hosted_page;
                }
                if (response.pending_invoices) {
                  window.location.href = `${API_URL}/v2/payment/${projectId}/collect-unpaid`;
                  cbInstance.closeAll();
                } else {
                  cbInstance.closeAll();
                  // eslint-disable-next-line no-alert
                  alert('Unable to process payment, please contact support');
                }
              })
              .catch(err => {
                cbInstance.closeAll();
                console.error(err);
                // eslint-disable-next-line no-alert
                alert('Unable to process payment, please contact support');
              });
          },
          error: () => {
            try {
              trackEvent('checkout_error', { project_id: projectId });
            } catch (error) { }
          },
          close: () => {
            // console.log('checkout closed');
          },
          success: hostedPageId => {
            return fetch(
              `${API_URL}/v2/payment/${projectId}/chargebee-success?hostedPageId=${hostedPageId}`,
              {
                credentials: 'include'
              }
            )
              .then(response => {
                return response.json();
              })
              .then(() => {
                try {
                  trackEvent('checkout_complete', { project_id: projectId });
                } catch (error) { }
                window.location.reload();
              })
              .catch(() => {
                try {
                  trackEvent(
                    'checkout_error',
                    { project_id: projectId, email },
                    {
                      mautic: true
                    }
                  );
                } catch (error) { }
              });
          },
          step: value => {
            try {
              trackEvent('checkout_step', {
                step: value,
                project_id: projectId
              });
            } catch (error) { }
          }
        });
      }
    }

    if (template === 'woocommerce') {
      return (
        <React.Fragment>
          {showPaymentButton && (
            <Button className={paymentButtonClass} onClick={toggle}>
              {paymentText}
            </Button>
          )}
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} className="border-bottom-0" />
            <ModalBody className="p-3">
              <Container style={{ textAlign: 'center' }}>
                <Button
                  color="success"
                  disabled={loading}
                  onClick={proceed}
                  className="px-5"
                >
                  {loading ? (
                    <React.Fragment>Loading...</React.Fragment>
                  ) : (
                    <React.Fragment>Make payment</React.Fragment>
                  )}
                </Button>
              </Container>
            </ModalBody>
          </Modal>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        {showPaymentButton && (
          <Button className={paymentButtonClass} onClick={toggle}>
            {paymentText}
          </Button>
        )}
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle} className="border-bottom-0">
            Choose a plan for your store
          </ModalHeader>
          <ModalBody className="p-3">
            <Container>
              <Row className="justify-content-center">
                {Object.keys(plans).map(id => {
                  const {
                    planName,
                    offerPriceYearly,
                    monthlyPrice,
                    yearlyPrice,
                    revenueDisc,
                    perfectFor,
                    skipMonthly = false,
                    yearlyPriceInMonth = false
                  } = plans[id];
                  let price;
                  const regionalPlan = getRegionalPlan(plans[id]);
                  let currencySymbol = '$';
                  if (regionalPlan !== false && regionalPlan.price) {
                    price = regionalPlan.price;
                    if (regionalPlan.currencySymbol)
                      currencySymbol = regionalPlan.currencySymbol;
                  } else if (!skipMonthly && yearly === false) {
                    price = monthlyPrice;
                  } else {
                    price = yearlyPrice;
                  }
                  return (
                    <Col
                      className="px-1 text-center"
                      xs="12"
                      sm="12"
                      md="3"
                      lg="3"
                      key={`plan-${id}`}
                    >
                      <PlanListing
                        id={id}
                        onSelect={() => {
                          if (skipMonthly === true) {
                            setSkipToggleButton(true);
                          } else {
                            setSkipToggleButton(false);
                          }
                          trackEvent('checkout_plan_selected', {
                            project_id: projectId,
                            plan: id
                          });
                          setPackageId(id);
                        }}
                        planName={planName}
                        price={price}
                        revenueDisc={revenueDisc}
                        perfectFor={perfectFor}
                        offerPriceYearly={offerPriceYearly}
                        currencySymbol={currencySymbol}
                        showYearOnly={
                          yearlyPriceInMonth
                            ? false
                            : skipMonthly
                              ? true
                              : yearly
                        }
                        defaultChecked={id === packageId}
                      />
                    </Col>
                  );
                })}
              </Row>
            </Container>
            {/* <Container>
              <div className="bg-purple p-3 rounded">
                <Row className="align-items-center">
                  <Col sm="12" lg={template === 'woocommerce' ? '8' : '12'}>
                    <h5 className="font-weight-bold text-white">
                      40% OFF on Annual Pricing.
                    </h5>
                    <p className="mb-0 text-white">
                      Get 40% off on annual subscription of{' '}
                      {template === 'woocommerce' ? 'Scale and growth' : 'all'}{' '}
                      plans as part of our{' '}
                      <a
                        href={`https://appmaker.xyz/black-friday-cyber-monday-2021#${template}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-white"
                      >
                        Black Friday - Cyber Monday sale!
                      </a>{' '}
                      {template === 'woocommerce' && (
                        <span>Apply coupon code BFCM21 at checkout.</span>
                      )}
                    </p>
                  </Col>
                  {template === 'woocommerce' && (
                    <Col sm="12" lg="4" className="text-white">
                      <CopyField id="copy" label="Coupon Code" value="BFCM21" />
                    </Col>
                  )}
                </Row>
              </div>
            </Container> */}
          </ModalBody>
          <ModalFooter className="justify-content-center">
            {!skipToggleButton && (
              <React.Fragment>
                <p className="mb-0">
                  <b>Monthly Billing</b>
                  {/* <small> (Save upto 20%)</small> */}
                </p>
                <CustomInput
                  type="switch"
                  id="switchBillingType"
                  name="billingType"
                  checked={yearly}
                  label={
                    yearly === false ? (
                      template === 'woocommerce' ? (
                        <b>Annual Billing (20% Off)</b>
                      ) : (
                        <b>Annual Billing (~20% Off)</b>
                      )
                    ) : template === 'woocommerce' ? (
                      <b>Annual Billing (20% Off)</b>
                    ) : (
                      <b>Annual Billing (~20% Off)</b>
                    )
                  }
                  className="mr-4 cursor-pointer"
                  onChange={billToggle}
                />
              </React.Fragment>
            )}
            <Button
              color="success"
              disabled={loading}
              onClick={proceed}
              className="px-5"
            >
              {loading ? (
                <React.Fragment>Loading...</React.Fragment>
              ) : (
                <React.Fragment>Proceed</React.Fragment>
              )}
            </Button>
          </ModalFooter>
          {template === 'woocommerce' && (
            <div className="choose-plan-footer text-center py-1 bg-light">
              {/* <p className="mb-0">Large business with custome requirements? <a href="#" className="btn btn-outline-primary btn-pill btn-sm ml-3">Contact us</a></p> */}
              <a
                href={`https://appmaker.xyz/${template}/pricing`}
                className="btn btn-link mb-0"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => {
                  trackEvent('detailed_pricing_click', {
                    project_id: projectId
                  });
                }}
              >
                Detailed pricing&nbsp;
                <small>
                  <i className="fas fa-external-link-alt" />
                </small>
              </a>
            </div>
          )}
        </Modal>
      </React.Fragment>
    );
  }
);

export default ChoosePlan;
