export default {
  free: {
    planName: 'Free plan',
    monthlyPrice: 0,
    yearlyPrice: 0,
    revenueDisc: '2% fee on app orders',
    perfectFor: 'Business in starting stage.',
    shopifyId: {
      monthly: { usd: 101 }, // BillingPackageCost id
      yearly: {
        // usd: 91,
        usd: 101
      }
    }
  },
  basic: {
    planName: 'Basic plan',
    offerPriceYearly: 990,
    monthlyPrice: 99,
    yearlyPrice: 1188,
    revenueDisc: '',
    perfectFor: 'Small business',
    shopifyId: {
      monthly: { usd: 92 }, // BillingPackageCost id
      yearly: {
        usd: 91
      }
    }
  },
  growth: {
    planName: 'Growth plan',
    offerPriceYearly: 2490,
    monthlyPrice: 249,
    yearlyPrice: 2988,
    revenueDisc: '',
    perfectFor: 'For business in the growth stage.',
    shopifyId: {
      monthly: { usd: 94 },
      yearly: {
        usd: 93
      }
    }
  },
  scale: {
    planName: 'Scale plan',
    offerPriceYearly: 9990,
    monthlyPrice: 999,
    yearlyPrice: 11988,
    revenueDisc: '',
    perfectFor: 'For business in the scale stage.',
    shopifyId: {
      monthly: { usd: 96 },
      yearly: {
        usd: 95
      }
    }
  }
};
