import { dispatch } from '@wordpress/data';
// import { REDUCER_KEY } from './store/name';
const REDUCER_KEY = 'woocommerce';
const coreActions = [
  {
    id: 'OPEN_IN_WEBVIEW',
    title: 'Open WebView',
    template: 'common',
    subAction: {
      id: 'url',
      title: 'Choose URL',
      type: 'text'
    }
  },
  {
    id: 'OPEN_URL',
    title: 'Open URL',
    template: 'common',
    subAction: {
      id: 'url',
      title: 'Choose URL',
      type: 'text'
    }
  },
  {
    id: 'OPEN_PRODUCT',
    title: 'Open Product',
    template: 'woocommerce',
    subAction: {
      id: 'PRODUCT_ID',
      title: 'Choose Products',
      type: 'select',
      dataSource: {
        kind: 'root',
        name: 'product',
        link: 'products',
        store: REDUCER_KEY,
        appendLanguage: true
      }
    }
  },
  {
    id: 'OPEN_PRODUCT_BY_CATEGORY',
    title: 'Open Product by Category',
    template: 'woocommerce',
    subAction: {
      id: 'PRODUCT_ID',
      title: 'Choose Category',
      type: 'select',
      dataSource: {
        kind: 'root',
        name: 'category',
        link: 'product_category',
        store: REDUCER_KEY,
        appendLanguage: true,
        showAll: true
      }
    }
  },
  {
    id: 'OPEN_PRODUCT_BY_CATEGORY_BROWSE',
    title: 'Open Product by Category Browse',
    template: 'woocommerce',
    subAction: {
      id: 'PRODUCT_ID',
      title: 'Choose Category',
      type: 'select',
      dataSource: {
        kind: 'root',
        name: 'category',
        link: 'product_category',
        store: REDUCER_KEY,
        appendLanguage: true,
        showAll: true
      }
    }
  },
  {
    id: 'OPEN_PRODUCT_TAG',
    title: 'Open Product by Tag',
    template: 'woocommerce',
    subAction: {
      id: 'PRODUCT_ID',
      title: 'Choose Product Tag',
      type: 'select',
      dataSource: {
        kind: 'root',
        name: 'tag',
        link: 'product_tag',
        store: REDUCER_KEY,
        appendLanguage: true
      }
    }
  },
  {
    id: 'OPEN_CATEGORY',
    title: 'Open Post Category',
    template: 'woocommerce',
    subAction: {
      id: 'PRODUCT_ID',
      title: 'Choose Category',
      type: 'select',
      dataSource: {
        kind: 'root',
        name: 'post_category',
        link: 'category',
        store: REDUCER_KEY,
        appendLanguage: true,
        showAll: true
      }
    }
  },
  {
    id: 'OPEN_POST_PAGE',
    title: 'Open Post/Page',
    template: 'woocommerce',
    subAction: {
      id: 'PRODUCT_ID',
      title: 'Choose Post/Page',
      type: 'select',
      dataSource: {
        kind: 'root',
        name: 'post_page',
        link: 'posts',
        store: REDUCER_KEY,
        appendLanguage: true
      }
    }
  },
  {
    id: 'OPEN_ATTRIBUTE',
    title: 'Open Attribute',
    template: 'woocommerce',
    subAction: {
      id: 'ATTRIBUTE_ID',
      title: 'Choose Attribute',
      type: 'select',
      subAction: {
        id: 'TERM_ID',
        title: 'Choose Attribute Term',
        type: 'select',
        dataSource: {
          kind: 'root',
          name: 'attributes',
          link: 'attributes_category?taxonomy=',
          depend: true,
          store: REDUCER_KEY,
          appendLanguage: true
        }
      },
      dataSource: {
        kind: 'root',
        name: 'attributes',
        link: 'attributes',
        store: REDUCER_KEY,
        appendLanguage: true
      }
    }
  },
  {
    id: 'OPEN_SEARCH',
    title: 'Open Search',
    template: 'woocommerce'
  },
  {
    id: 'CURRENCY_SWITCHER',
    title: 'Currency Switcher',
    template: 'woocommerce'
  },
  {
    id: 'LANGUAGE_SWITCHER',
    title: 'Language Switcher',
    template: 'woocommerce'
  },
  {
    id: 'OPEN_VENDORS',
    title: 'Open Vendor List',
    template: 'woocommerce'
  },
  {
    id: 'OPEN_BRANDS',
    title: 'Open Brands',
    template: 'woocommerce'
  },
  {
    id: 'OPEN_BY_BRAND',
    title: 'Open Brands by Category',
    template: 'woocommerce',
    subAction: {
      id: 'CATEGORY_ID',
      title: 'Choose Category',
      type: 'select',
      dataSource: {
        kind: 'root',
        name: 'category',
        link: 'product_category',
        store: REDUCER_KEY,
        appendLanguage: true
      }
    }
  },
  {
    id: 'OPEN_PRODUCT_BRAND',
    title: 'Open by Brands',
    template: 'woocommerce',
    subAction: {
      id: 'BRAND_ID',
      title: 'Choose Brand',
      type: 'select',
      dataSource: {
        kind: 'root',
        name: 'brand',
        link: 'product_brand',
        store: REDUCER_KEY,
        appendLanguage: true
      }
    }
  },
  {
    id: 'OPEN_SHOP_PAGE',
    title: 'Open Shop Page',
    template: 'woocommerce',
    subAction: {
      id: 'PRODUCT_ID',
      title: 'Choose Category',
      type: 'select',
      dataSource: {
        kind: 'root',
        name: 'category',
        link: 'product_category',
        store: REDUCER_KEY,
        appendLanguage: true,
        showAll: true
      }
    }
  },
  {
    id: 'OPEN_AUTHENTICATED_WEBVIEW',
    title: 'Open Authenticated Webview',
    template: 'woocommerce',
    subAction: {
      id: 'url',
      title: 'Choose URL',
      type: 'text'
    }
  },
  {
    id: 'LIST_NEW_PRODUCTS',
    title: 'List New Products',
    template: 'woocommerce',
    subAction: {
      id: 'PRODUCT_ID',
      title: 'Choose Category',
      type: 'select',
      dataSource: {
        kind: 'root',
        name: 'category',
        link: 'product_category',
        store: REDUCER_KEY,
        appendLanguage: true,
        showAll: true
      }
    }
  },
  {
    id: 'OPEN_PRE_BUILD',
    title: 'Open Pre-build Products',
    template: 'woocommerce',
    subAction: {
      id: 'OPTION_ID',
      title: 'Choose Options',
      type: 'select-options',
      dataSource: {
        options: [
          {
            value: 'recent',
            label: 'Recent Products'
          },
          {
            value: 'best_selling',
            label: 'Best Selling Products'
          },
          {
            value: 'top_rated',
            label: 'Top Rated Products'
          },
          {
            value: 'featured',
            label: 'Featured Products'
          },
          {
            value: 'sale',
            label: 'Sale Products'
          }
        ]
      }
    }
  },
  {
    id: 'CATEGORY_WITH_PRODUCT',
    title: 'Category with Product',
    template: 'woocommerce',
    subAction: {
      id: 'CATEGORY_ID',
      title: 'Choose Category',
      type: 'select',
      dataSource: {
        kind: 'root',
        name: 'category',
        link: 'product_category',
        store: REDUCER_KEY,
        appendLanguage: true
      }
    }
  },
  {
    id: 'COPY_TO_CLIPBOARD',
    title: 'Copy to clipboard',
    template: 'common',
    subAction: {
      id: 'clipboard_text',
      title: 'Choose Text',
      type: 'text'
    }
  },
  { id: 'NO_ACTION', title: 'No Action', template: 'woocommerce' }
];
// {
//   id: 'OPEN_PUSH_HISTORY',
//     title: 'Open push notification history',
//       template: 'woocommerce'
// }
export function registerActionType(actionType) {
  return dispatch('core/actions').addActionTypes(actionType);
}
export function registerActionTypes() {
  coreActions.map(registerActionType);
}

// {
//   id: 'OPEN_ZOPIM_CHAT',
//   title: 'Open Zopim Chat',
//   template: 'woocommerce'
// },
// subAction: {
//   id: 'ORDER_TYPE',
//   title: 'Choose order',
//   type: 'select-simple',
//   dataSource: {
//     kind: 'root',
//     name: 'order',
//     options: [
//       {
//         value: 'default',
//         label: 'Default',
//         params: {
//           order: 'asc',
//           orderby: 'default'
//         }
//       },
//       {
//         value: 'newest_first',
//         label: 'Newest First',
//         params: {
//           order: 'desc',
//           orderby: 'date'
//         }
//       },
//       {
//         value: 'price_low_to_high',
//         label: 'Price - Low to High',
//         params: {
//           order: 'asc',
//           orderby: 'price'
//         }
//       },
//       {
//         value: 'price_high_to_low',
//         label: 'Price - High to Low',
//         params: {
//           order: 'desc',
//           orderby: 'price'
//         }
//       },
//       {
//         value: 'popularity',
//         label: 'Popularity',
//         params: {
//           order: 'desc',
//           orderby: 'popularity'
//         }
//       },
//       {
//         value: 'rating',
//         label: 'Rating',
//         params: {
//           order: 'desc',
//           orderby: 'rating'
//         }
//       }
//     ],
//     depend: true,
//     store: REDUCER_KEY,
//     appendLanguage: true
//   }
// }
