import React from 'react';
import { withSelect } from '@wordpress/data';
import { useToasts } from 'react-toast-notifications';
import WooCommerce from './template/woocommerce';
import WordPress from './template/wordpress';
import Shopify from './template/shopify';

const CustomerData = props => {
  const { projectId, isAdmin = false, template } = props;
  const { addToast } = useToasts();
  if (!isAdmin) {
    return null;
  }
  switch (template) {
    case 'woocommerce': {
      return (
        <WooCommerce
          projectId={projectId}
          isAdmin={isAdmin}
          addToast={addToast}
        />
      );
    }
    case 'wordpress': {
      return (
        <WordPress
          projectId={projectId}
          isAdmin={isAdmin}
          addToast={addToast}
        />
      );
    }
    case 'shopify': {
      return (
        <Shopify projectId={projectId} isAdmin={isAdmin} addToast={addToast} />
      );
    }
    default: {
      return null;
    }
  }
};

export default withSelect(select => {
  const project = select('appmaker/core').getCurrentProject();
  const user = select('appmaker/core').getUser();
  return {
    projectId: project.id,
    isAdmin: user && user.email && user.email.includes('@appmaker.xyz'),
    template: project.template
  };
})(CustomerData);
