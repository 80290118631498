import React, { useEffect, useState } from 'react';
import qs from 'qs';
import axios from 'axios';
import { Redirect } from '@shopify/app-bridge/actions';
import { Redirect as RouterRedirect } from 'react-router-dom';
import { BallBeat } from 'react-pure-loaders';

import * as ROUTES from '../../constants/routes';
/* eslint-disable */
export default function ShopifyInstall(props) {
    const [user, setUser] = useState(false);
    const queryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    const shop = queryParams.shop || queryParams['?shop'];

    function redirectToShopifyLogin() {
        const shopifyLoginUrl = `${ROUTES.API_URL}/v2/user/shopify/login?shop=${shop}`;
        if (!window.shopifyApp) {
            window.location.href = shopifyLoginUrl;
        } else if (window.shopifyApp) {
            const redirect = Redirect.create(window.shopifyApp);
            redirect.dispatch(Redirect.Action.REMOTE, shopifyLoginUrl);
        }
    }

    useEffect(() => {
        if (shop) {
            const url = `${ROUTES.API_URL}/v2/user`;
            axios({
                method: 'GET',
                url,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Cache: 'no-cache',
                },
                // withCredentials: true // Removed to prevent login into appmaker on shopify re-install
            })
                .then(response => {
                    const userResponse = response.data;
                    if (!userResponse.logged_in) {
                        redirectToShopifyLogin();
                    } else {
                        if (userResponse.id && userResponse.projectId) {
                            setUser(userResponse);
                        } else {
                            redirectToShopifyLogin();
                        }
                    }
                })
                .catch((err) => {
                    console.error(err);
                });
        }
    });

    if (!shop) return <RouterRedirect push to={`/`} />
    return user ? <RouterRedirect push to={`/apps/${user.projectId}`} /> : (
        <div className="wrap overflow-auto pr-3 d-flex justify-content-center align-items-center">
            <BallBeat color="#123abc" loading />
        </div>
    );
}