import React, { useState } from 'react';
import { Row, Col, Container } from 'reactstrap';
import { Link } from 'react-router-dom';

const SetupInstructions = ({ appId }) => {
  const [show, setShow] = useState(true);
  const toggle = () => {
    setShow(!show);
  };
  if (show) {
    return (
      <Container className="dashboard-notice-wrapper border-0 rounded-lg position-relative">
        <div className="text-right">
          <button
            className="btn"
            type="button"
            onClick={() => {
              toggle();
            }}
          >
            <i className="far fa-times-circle text-muted" />
          </button>
        </div>
        <Row>
          <Col xs="12" sm="12" md="6" lg="4" className="mb-3">
            <h3>
              <i className="fas fa-rocket d-block text-primary mb-2" />
              Setup your app
            </h3>
            <p className="mb-0 text-muted">
              Customize, test and publish your app
            </p>
          </Col>
          <Col xs="12" sm="12" md="6" lg="8">
            <ol className="list-unstyled">
              <li className="mb-2">
                <h6 className="text-dark font-weight-bold">
                  Step 1: <span className="text-primary">Customize App</span>
                </h6>
                <p>
                  Update app home screen and drawer menu by adding blocks
                  provided in the customize app section
                </p>
                <p>
                  <Link
                    to={`/apps/${appId}/inAppPage/home`}
                    className="btn btn-primary btn-sm"
                  >
                    Customize my App
                  </Link>
                </p>
              </li>
              <li className="mb-2">
                <h6 className="text-dark font-weight-bold">
                  Step 2: <span className="text-primary">Test App</span>
                </h6>
                <p>
                  On app preview, test and verify your apps functionalities.
                </p>
              </li>
              <li>
                <h6 className="text-dark font-weight-bold">
                  Step 3:{' '}
                  <span className="text-primary">Upload to store(s)</span>
                </h6>
                <p className="mb-0">
                  Upload your app to PlayStore and AppStore following guides. <br />
                  <a
                    className="btn btn-primary btn-sm"
                    target="_blank"
                    href="https://appmaker.xyz/en/docs/how-to-publish-an-android-app-in-playstore-2/"
                  >
                    PlayStore instructions
                  </a>{' '}
                  <a
                    className="btn btn-primary btn-sm"
                    target="_blank"
                    href="https://appmaker.xyz/en/docs/how-to-upload-ios-app-to-appstore/"
                  >
                    AppStore instructions
                  </a>
                </p>
              </li>
            </ol>
          </Col>
        </Row>
      </Container>
    );
  }
  return null;
};

export default SetupInstructions;
