import React from 'react';
import Select from 'react-select';

export default props => {
  const { schema = {} } = props;
  const { options = [] } = schema;
  return (
    <Select
      defaultValue={props.value}
      isMulti
      options={options}
      className="basic-multi-select"
      classNamePrefix="select"
      onChange={value => {
        props.onChange(value);
      }}
    />
  );
};
