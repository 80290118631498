import React from 'react';
import { Row, Col } from 'reactstrap';
import Loading from '../Loading';
import CreateTicketGitlab from '../../../../GitlabAutomation';

export default props => {
  const {
    appIcon,
    appName,
    storeUrl,
    projectId,
    chargebeeId,
    userId,
    activePlan,
    currency,
    country,
    lastActivity,
    createDate,
    expiryDate,
    paymentDate,
    isLoadingSalesData,
    addToast = () => {}
  } = props;

  return (
    <React.Fragment>
      <div className="app-details mb-4 pb-4 border-bottom border-light">
        <Row className="m-0 align-items-center">
          <Col md="8" className="p-0">
            <div className="d-flex align-self-center mb-2">
              <img
                src={appIcon}
                alt="app-icon"
                className="mr-3"
                width="50px"
                height="50px"
              />
              <span>
                <h5>{appName}</h5>
                <a
                  href={storeUrl}
                  className="text-grey"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fas fa-link mr-2" />
                  {storeUrl}
                </a>
              </span>
            </div>
            <small className="text-grey d-block">
              Last activity:
              {lastActivity}
            </small>
            <small className="text-grey d-block">
              Created:
              {createDate}
            </small>
            <small className="text-grey d-block">
              Expiry:
              {expiryDate}
            </small>
            <small className="text-grey d-block">
              Order Payment: 
{' '}
{paymentDate}
            </small>
          </Col>
        </Row>
      </div>
      <div className="mb-4 pb-4 border-bottom border-light">
        <CreateTicketGitlab
          projectId={projectId}
          buttonLabel="Create Gitlab ticket"
        />
      </div>
      <div className="app-actions mb-4 pb-4 border-bottom border-light">
        <div className="d-flex justify-content-around mb-4">
          {!isLoadingSalesData && (
            <React.Fragment>
              <span className="text-center">
                <h4 className="font-weight-bold mb-0">{activePlan}</h4>
                <small className="text-grey">Plan</small>
                {/* <CreditLimit projectId={projectId} /> */}
              </span>
              <span className="text-center">
                <h4 className="font-weight-bold mb-0">{currency}</h4>
                <small className="text-grey">Currency</small>
              </span>
              <span className="text-center">
                <h4 className="font-weight-bold mb-0">{country}</h4>
                <small className="text-grey">Country</small>
              </span>
            </React.Fragment>
          )}
        </div>

        <div className="action-buttons d-flex justify-content-between">
          <span>
            <a
              href={`https://appmaker.xyz/admin/project-meta/?ProjectMeta%5Bproject_id%5D=${projectId}`}
              className="btn btn-primary btn-sm m-1"
              target="_blank"
            >
              Meta
            </a>
            <a
              href={`https://appmaker.xyz/admin/projects/extend-trial-five-days?project_id=${projectId}`}
              className="btn btn-primary btn-sm m-1"
              target="_blank"
            >
              Extend Trial
            </a>
            <a
              href={`https://appmaker.xyz/admin/projects/intercept?project_id=${projectId}`}
              className="btn btn-primary btn-sm m-1"
              target="_blank"
            >
              Intercept
            </a>
            <a
              href={`https://appmaker.chargebee.com/admin-console/subscriptions/${chargebeeId}`}
              className="btn btn-primary btn-sm m-1"
              target="_blank"
            >
              Chargebee
            </a>
            <a
              href={`https://mixpanel.com/project/2357733/view/2902789/app/profile#distinct_id=${userId}`}
              className="btn btn-primary btn-sm m-1"
              target="_blank"
            >
              Mixpanel
            </a>
          </span>
          <span>
            <a
              href={`https://gitlab.com/appilder-core/issues-sink/-/milestones?utf8=✓&search_title=${projectId}`}
              className="btn btn-light btn-sm m-1"
            >
              <i className="fab fa-gitlab text-orange" />
            </a>
          </span>
        </div>
      </div>
    </React.Fragment>
  );
};
