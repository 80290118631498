import { mauticFormSubmit } from '../api-call/mauticApi';

export const formIdList = {
  inAppPageSave: 16,
  inAppPageCreate: 17,
  menuSave: 18,
  sendPush: 20,
  settingsSave: 23,
  paymentStart: 21,
  paymentFailed: 27,
  promoteAppSaved: 24
};

export const sendToMautic = async (
  eventName,
  {
    email,
    project_id,
    other = {},
    type,
    state,
    blocks_count,
    page_id,
    menu_count
  }
) => {
  try {
    switch (eventName) {
      case 'push_notification_send': {
        const data = {
          mauticform: {
            formId: formIdList.sendPush,
            email,
            project_id
          }
        };
        return mauticFormSubmit(data);
      }
      case 'promote_app_saved': {
        const data = {
          mauticform: {
            formId: formIdList.promoteAppSaved,
            email,
            project_id,
            type,
            state1: state
          }
        };
        return mauticFormSubmit(data);
      }
      case 'app_settings_saved': {
        const data = {
          mauticform: {
            formId: formIdList.settingsSave,
            email,
            project_id
          }
        };
        return mauticFormSubmit(data);
      }
      case 'payment_start': {
        const data = {
          mauticform: {
            formId: formIdList.paymentStart,
            email,
            project_id
          }
        };
        return mauticFormSubmit(data);
      }
      case 'checkout_error': {
        const data = {
          mauticform: {
            formId: formIdList.paymentFailed,
            email,
            project_id
          }
        };
        return mauticFormSubmit(data);
      }
      case 'in_app_page_saved': {
        const data = {
          mauticform: {
            formId: formIdList.inAppPageSave,
            email,
            project_id,
            blocks_count,
            page_id
          }
        };
        return mauticFormSubmit(data);
      }
      case 'menu_saved': {
        const data = {
          mauticform: {
            formId: formIdList.menuSave,
            email,
            project_id,
            menu_count
          }
        };
        return mauticFormSubmit(data);
      }
      case 'inapp_page_create': {
        const data = {
          mauticform: {
            formId: formIdList.inAppPageCreate,
            email,
            project_id,
            page_id
          }
        };
        console.log(data);
        return mauticFormSubmit(data);
      }
      default:
        return true;
    }
  } catch (error) {
    console.error("Mautic event error", error);
  }
};
