import React from 'react';
import Select from 'react-select';
import Languages from '../../lib/languages';

const convertOption = (values, { defaultLanguageCode, inAppPageId } = {}) => {
  const option = [];

  option.push({
    value: defaultLanguageCode || 'default',
    label: defaultLanguageCode
      ? `${Languages[defaultLanguageCode]} (Default)`
      : Languages.default
  });
  values.forEach(data => {
    try {
      option.push({
        value: data,
        label: Languages[data]
      });
    } catch (error) {}
  });
  return option;
};
const getCurrentOption = data => {
  const option = {
    value: data,
    label: Languages[data]
  };
  return option;
};
export default props => {
  const {
    onChange = false,
    defaultValue,
    isDisabled,
    defaultLanguageCode,
    inAppPageId
  } = props;
  // console.log({ id: defaultValue, label: Languages[`${defaultValue}`] });
  return (
    <Select
      isDisabled={isDisabled}
      value={getCurrentOption(defaultValue)}
      options={convertOption(props.languages, {
        defaultLanguageCode,
        inAppPageId
      })}
      onChange={value => {
        if (onChange !== false) {
          onChange(value);
        }
      }}
    />
  );
};
