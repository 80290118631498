import React, { useState } from 'react';
import { CustomInput } from 'reactstrap';

export default props => {
  const { value = false, onChange, schema } = props;
  const checkedValue = value == '1' || value === true;
  return (
    <CustomInput
      type="switch"
      id={schema.key}
      name="billingType"
      checked={checkedValue}
      className="mr-4 cursor-pointer"
      onChange={values => {
        onChange(value == '1' || value === true ? '0' : '1');
      }}
    />
  );
};
