/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { encode, decode } from 'base-64';
import { Input } from 'reactstrap';
import { withToastManager } from 'react-toast-notifications';
import * as ROUTES from '../../../../constants/routes';
import { selectMulti, textAreaWithEncoding } from '../DynamicForms/widgets';
import { updateAppSetting } from '../../../../lib/apiFunctions';
import DynamicComponent from '../components/DynamicComponent';
import { trackEvent } from '../../../../utils/tracker';

class SettingsView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appId: this.props.appId,
      loading: false,
      isSaving: false,
      loaderForRerenderingWidget: false,
      fieldsFromApi: this.props.data.fields,
      valuesFromApi: this.props.data.values,
      convertedApi: {},
      uiSchema: {},
      requiredSyncWithPlugin: this.props.data.requiredSyncWithPlugin
    };

    this.childHandler = this.childHandler.bind(this);
    this.callbackFunction = this.callbackFunction.bind(this);
    this.onChangeChild = this.onChangeChild.bind(this);
  }
  // callback from api

  callbackFunction(status, data) {
    if (status) {
      const newState = this.state;
      newState.loading = false;
      newState.loaderForRerenderingWidget = false;
      this.createSchema(newState, newState.valuesFromApi);
    } else {
      alert('somthing went wrong');
    }
  }

  // onChangeChild
  onChangeChild(dataFromChild) {
    const currentStateData = this.state;
    currentStateData.loading = true;
    Object.keys(dataFromChild).forEach(element => {
      currentStateData.valuesFromApi[`${element}`] =
        dataFromChild[`${element}`];
    });
    currentStateData.loaderForRerenderingWidget = true;
    this.createSchema(currentStateData, currentStateData.valuesFromApi);
  }

  // handle the data from child
  childHandler(dataFromChild) {
    const currentStateData = this.state;
    currentStateData.loading = true;
    Object.keys(dataFromChild).forEach(element => {
      currentStateData.valuesFromApi[`${element}`] =
        dataFromChild[`${element}`];
    });
    currentStateData.loaderForRerenderingWidget = true;
    this.createSchema(currentStateData, currentStateData.valuesFromApi);
    this.saveAppSettings(currentStateData.valuesFromApi);
  }

  // save app settings
  async saveAppSettings(data) {
    const { toastManager } = this.props;
    try {
      try {
        trackEvent(
          'app_settings_saved',
          { project_id: this.state.appId, email: this.props.email },
          {
            mautic: true
          }
        );
      } catch (error) {}
      this.setState({ isSaving: true });
      const response = await updateAppSetting(this.state.appId, data);
      if (response.status !== 400) {
        toastManager.add('Saved successfully', {
          appearance: 'success',
          autoDismiss: true
        });
      } else {
        try {
          trackEvent('app_settings_save_error', {
            project_id: this.state.appId
          });
          toastManager.add('Unable to  Save', {
            appearance: 'error',
            autoDismiss: true
          });
        } catch (error) {}
      }
    } catch (error) {
      try {
        trackEvent('app_settings_save_error', { project_id: this.state.appId });
      } catch (error) {}
      toastManager.add('Unable to  Save', {
        appearance: 'error',
        autoDismiss: true
      });
    }
    this.setState({ isSaving: false });
  }

  // to create scheme for react-jsonschema-forms
  createSchema(currentState, valuesFromApi) {
    // console.log('createSchema',currentState, valuesFromApi);
    /*
            fieldsFromApi={
                'fieldSettingsId:{
                    "title": "My Form",
                    "description": "My Form",
                    "type": "object",
                    "required": [
                        "fieldId1"
                    ],
                    "properties": {
                      "fieldId": {
                        "type": "string",
                        "title": "fieldName1"
                      },
                      "fieldId2": {
                        "type": "string",
                        "title": "fieldName2"
                      }
                    }
                }
            }
        */
    // 'this.props.data.fields'(this.state.fieldsFromApi) contains the fields from api
    // 'this.props.data.values'(this.state.valuesFromApi) contains the values for the field id in the above fields

    const currentStateData = currentState;
    currentStateData.fieldsFromApi.forEach(field => {
      const fieldsToSet = {
        type: 'object',
        required: []
      };

      const properties = {};
      field.fields.forEach(fieldsToSet => {
        switch (fieldsToSet.type) {
          case 'text':
            properties[`${fieldsToSet.id}`] = {
              type: 'string',
              title: fieldsToSet.label,
              default: valuesFromApi[`${fieldsToSet.id}`]
            };
            break;
          case 'select':
            // check for mult select from api
            if (fieldsToSet.multi === true) {
              // to change the api to select tag attributes
              const out = fieldsToSet.data_source.data.map(data => {
                return { value: data.id, label: data.label };
              });
              properties[`${fieldsToSet.id}`] = {
                type: 'string',
                title: fieldsToSet.label
              };
              const defaultValueMulti = [];
              if (valuesFromApi[`${fieldsToSet.id}`] !== undefined) {
                valuesFromApi[`${fieldsToSet.id}`].forEach(defValue => {
                  out.forEach(value => {
                    if (value.value === defValue) {
                      defaultValueMulti.push(value);
                    }
                  });
                });
              }
              const uiSchema = selectMulti(
                fieldsToSet.id,
                defaultValueMulti,
                out
              );
              currentStateData.uiSchema[`${fieldsToSet.id}`] = uiSchema;
            } else {
              const enumData = [];
              const enumNamesData = [];
              // to change the api to select tag attributes
              fieldsToSet.data_source.data.forEach(data => {
                enumData.push(data.id);
                enumNamesData.push(data.label);
              });
              properties[`${fieldsToSet.id}`] = {
                type: 'string',
                title: fieldsToSet.label,
                enum: enumData,
                enumNames: enumNamesData,
                default: valuesFromApi[`${fieldsToSet.id}`]
              };
            }
            break;
          case 'textarea':
            if (fieldsToSet.isEncoding !== true) {
              properties[`${fieldsToSet.id}`] = {
                type: 'string',
                title: fieldsToSet.label,
                default: valuesFromApi[`${fieldsToSet.id}`]
              };
              const uiWidget = {
                'ui:widget': 'textarea'
              };
              currentStateData.uiSchema[`${fieldsToSet.id}`] = uiWidget;
            } else {
              properties[`${fieldsToSet.id}`] = {
                type: 'string',
                title: fieldsToSet.label,
                default: valuesFromApi[`${fieldsToSet.id}`]
              };
              const uiWidget = textAreaWithEncoding(
                fieldsToSet.id,
                valuesFromApi[`${fieldsToSet.id}`]
              );

              currentStateData.uiSchema[`${fieldsToSet.id}`] = uiWidget;
            }
            break;
          case 'title':
            properties[`${fieldsToSet.label}`] = {
              type: 'object',
              title: `${fieldsToSet.label}`
            };

            break;
          default:
        }
      });
      fieldsToSet.properties = properties;
      currentStateData.convertedApi[`${field.id}`] = fieldsToSet;
    });
    this.setState(currentStateData);
  }

  componentDidMount() {
    this.createSchema(
      this.state,
      this.state.valuesFromApi ? this.state.valuesFromApi : {}
    );
  }

  render() {
    const { tab = false, data } = this.props;
    const tabContents = this.state.fieldsFromApi.map((field, index) => {
      if (tab == field.id || (!tab && index === 0)) {
        return (
          <DynamicComponent
            componentId={field.id}
            title={field.title}
            isSaving={this.state.isSaving}
            {...this.state}
            action={this.childHandler}
            saveState={this.state.loading}
            requiredSyncWithPlugin={this.state.requiredSyncWithPlugin}
          />
        );
      }
    });
    return (
      <div className="bg-white p-4 rounded-lg dashboard-box w-75">
        {tabContents}
      </div>
    );
  }
}

export default withToastManager(SettingsView);
