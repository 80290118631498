import fetch from 'node-fetch';
import * as ROUTES from '../constants/routes';

const BASE_URL = ROUTES.API_URL;

/**
 * Get the list of project
 */
export const getAllProject = async ({ accessToken, id }) => {
  try {
    const response = await fetch(
      `${BASE_URL}/v2/projects/${id}/notifications/getAllProjects?accessToken=${accessToken}`,
      {
        method: 'GET',
        credentials: 'include'
      }
    );
    const respJson = await response.json();
    return respJson;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Create new project
 */
export const createNewProject = async ({ accessToken, projectName, id }) => {
  try {
    const response = await fetch(
      `${BASE_URL}/v2/projects/${id}/notifications/create`,
      {
        method: 'POST',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ projectName, accessToken })
      }
    );
    const respJson = await response.json();
    return respJson;
  } catch (error) {
    return Promise.reject(error);
  }
};
/**
 * Get firebase project data
 */
export const getAppData = async ({ accessToken, projectName, id }) => {
  try {
    const response = await fetch(
      `${BASE_URL}/v2/projects/${id}/notifications/getAppData?accessToken=${accessToken}&projectName=${projectName}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }
    );
    const respJson = await response.json();
    return respJson;
  } catch (error) {
    return Promise.reject(error);
  }
};
/**
 * Download file
 */
export const download = async ({
  accessToken,
  projectName,
  platform,
  appId,
  id
}) => {
  try {
    const response = await fetch(
      `${BASE_URL}/v2/projects/${id}/notifications/download?accessToken=${accessToken}&projectName=${projectName}&appId=${appId}&platform=${platform}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }
    );
    const respJson = await response.json();
    return respJson;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Creat android/ios app
 */
export const createApp = async ({ accessToken, projectName, platform, id }) => {
  try {
    const response = await fetch(
      `${BASE_URL}/v2/projects/${id}/notifications/createApp`,
      {
        method: 'POST',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ projectName, accessToken, platform })
      }
    );
    const respJson = await response.json();
    return respJson;
  } catch (error) {
    return Promise.reject(error);
  }
};
/**
 * check weather operation is done
 */
export const operations = async ({ accessToken, operationId, id }) => {
  try {
    const response = await fetch(
      `${BASE_URL}/v2/projects/${id}/notifications/operation`,
      {
        method: 'POST',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ operationId, accessToken })
      }
    );
    const respJson = await response.json();
    return respJson;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Trigger upload
 */
export const uploadAppToCloud = async ({
  accessToken,
  projectName,
  id,
  platform
}) => {
  try {
    const response = await fetch(
      `${BASE_URL}/v2/projects/${id}/notifications/upload-to-gcloud?accessToken=${accessToken}&projectName=${projectName}&platform=${platform}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }
    );
    const respJson = await response.json();
    return respJson;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 *
 * upload Service file
 */
export const uploadService = async ({ file, id }) => {
  try {
    const data = new FormData();
    data.append('file', file);
    data.append('id', id);
    const response = await fetch(
      `${BASE_URL}/v2/projects/${id}/notifications/upload-service-to-gcloud`,
      {
        method: 'POST',
        credentials: 'include',
        body: data,
        headers: {}
      }
    );
    const respJson = await response.json();
    return respJson;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Save FCM server key
 */
export const addFcmServerKey = async ({ projectId, data }) => {
  try {
    const url = `${BASE_URL}/v2/projects/${projectId}/notifications/add-fcm`;
    const response = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(data),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });
    const respJson = await response.json();
    return Promise.resolve(respJson);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Service upload status
 */
export const serviceUploadStatus = async ({ projectId }) => {
  try {
    const url = `${BASE_URL}/v2/projects/${projectId}/notifications/service-file-upload-status`;
    const response = await fetch(url, {
      method: 'GET',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });

    const respJson = await response.json();
    return Promise.resolve(respJson);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Create firebase project
 */
export const createNewFirebaseProject = async ({ projectId, data }) => {
  try {
    const url = `${BASE_URL}/v2/projects/${projectId}/notifications/createFirebaseProject`;
    const response = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(data),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });
    const respJson = await response.json();
    return Promise.resolve(respJson);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const UPDATE_APP_REDIRECT = (template, projectId = '') => {
  switch (template) {
    case 'woocommerce': {
      // return `https://appmaker.xyz/dashboard/apps/update/${projectId}`;
      return `https://v3.appmaker.xyz/apps/${projectId}/app-design`;
    }
    case 'wordpress': {
      return `https://appmaker.xyz/dashboard/apps/update/${projectId}`;
    }
    case 'customapp': {
      return `https://appmaker.xyz/dashboard/apps/update/${projectId}`;
    }
    case 'shopify': {
      // return `https://manage-v2.appmaker.xyz/apps/${projectId}/update-app-new`;
      return `https://v3.appmaker.xyz/apps/${projectId}/app-design`;
    }
    default:
      return ``;
  }
};

export const UPDATE = ``;
