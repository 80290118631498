import React from 'react';
import { Row } from 'reactstrap';
import ProjectDetails from '../components/ProjectDetails';
import UserDetails from '../components/UserDetails';
import Diagnostic from '../components/diagnostic';

const WooCommerce = ({ projectId, addToast = () => {} }) => {
  return (
    <React.Fragment>
      <Row>
        <ProjectDetails projectId={projectId} addToast={addToast} />
        <UserDetails projectId={projectId} />
        <Diagnostic projectId={projectId} />
      </Row>
    </React.Fragment>
  );
};

export default WooCommerce;
