import React from 'react';
import { Route } from 'react-router-dom';
import { BallBeat } from 'react-pure-loaders';
import { dispatch, withSelect } from '@wordpress/data';
import axios from 'axios';
import { Redirect } from '@shopify/app-bridge/actions';
import qs from 'qs';

import * as ROUTES from '../../constants/routes';
import { setUser } from '../../utils/tracker';

/**
 *
 *
 * @param {*} param0
 */

function LoginCheck({ projectId = false, user }) {
  if (window.Intercom) {
    window.Intercom('update', {
      hide_default_launcher: true
    });
  }

  const url = `${ROUTES.API_URL}/v2/user`;
  //  const url = `${ROUTES.API_URL}/v2/projects/${appId}`;
  Promise.resolve(user)
    .then(storedUserData => {
      if (storedUserData.email) return Promise.resolve(true);
      return axios({
        method: 'GET',
        url,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Cache: 'no-cache',
        },
        withCredentials: true
      })
        .then(response => {
          const userResponse = response.data;
          if (!userResponse.logged_in) {
            if (!window.shopifyApp) {
              console.log("Normal login redirect", window.shopifyApp);
              const ssoLoginUrl = `${ROUTES.API_URL}/v2/user/sso-login`;
              window.location.href = `${ssoLoginUrl}?return_to=${window.location.href}`;
            } else {
              const redirect = Redirect.create(window.shopifyApp);
              const queryParams = qs.parse(window.location.search, {
                ignoreQueryPrefix: true
              });
              const shopifyLoginUrl = `${ROUTES.API_URL}/v2/user/shopify/login?shop=${queryParams.shop}`;
              redirect.dispatch(Redirect.Action.REMOTE, shopifyLoginUrl);
            }
          } else {
            if (userResponse.id) {
              setUser(userResponse.id, userResponse); // Analytics
              dispatch('appmaker/core').setUser({
                ...userResponse,
                last_used_project: projectId
              });
            }
            return Promise.resolve(false);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    })
    .then(getProject => {
      if (getProject && projectId) {
        const projectUrl = `${ROUTES.API_URL}/v2/projects/${projectId}`;
        return axios({
          method: 'GET',
          url: projectUrl,
          withCredentials: true
        })
          .then(response => {
            return response.data;
          })
          .then(project => {
            dispatch('appmaker/core').setCurrentProject(project);
          });
      }
      return Promise.resolve();
    });


  return (
    <div className="wrap overflow-auto pr-3 d-flex justify-content-center align-items-center">
      <BallBeat color="#123abc" loading />
    </div>
  );
}

const AuthenticatedRoute = ({
  user,
  project,
  match,
  component: Component,
  ...otherProps
}) => {
  return (
    <Route
      {...otherProps} // eslint-disable-line
      render={props => {
        const projectID = props.match.params.id;
        return user.email && (!projectID || (projectID && project.id)) ? (
          <Component {...props} user={user} project={project} />
        ) : (
          <LoginCheck user={user} projectId={projectID} />
        );
      }}
    />
  );
};

export default withSelect((select, ownProps) => {
  const user = select('appmaker/core').getUser();
  const project = select('appmaker/core').getCurrentProject();
  return {
    user,
    project
  };
})(AuthenticatedRoute);
