import React from 'react';

const ImageScrollerImage = ({ imageSrc, children, smallIcon }) => {
  return (
    <div
      className="image-container"
      style={{ width: smallIcon ? '80px' : '180px' }}
    >
      <img src={imageSrc} width="100%" className="image-uploaded" />
      {children && <span className="image-title">{children}</span>}
    </div>
  );
};
export default ImageScrollerImage;
